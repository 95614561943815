import Dropdown from "@components/Dropdown";
import InputNumber from "@components/Formik/InputNumber";
import { Field } from "formik";
import { useFormikContext } from "formik";
import gstyles from "@gstyles/index";
import styles from "./People.module.scss";
import _ from "lodash";
import { useTranslationContext } from "@providers/TranslationProvider";
import cx from "classnames";

const View = ({ className, border = false }: any) => {
  const { i18n } = useTranslationContext();
  const form: any = useFormikContext();
  const handler = {
    onChange: (name: string) => (value: number | string) => {
      form && form.setFieldValue(name, value);
    },
  };
  return (
    <>
      <Dropdown
        arrow={false}
        border={border}
        name={"people"}
        className={cx(styles.dropdown, className)}
        optionsProps={{ className: styles.option }}
        allowClickOption={true}
        typeClickOutside="body"
        label={
          <div className={styles.label}>
            {gstyles.icons({
              name: "users-1-outline",
              className: styles.icon,
              alt: "user",
            })}
            <span>{`${_.get(form, "values.num_of_adults", 0)} ${i18n.t(
              "SearchControl.adults"
            )}, ${_.get(form, "values.num_of_children", 0)} ${i18n.t(
              "SearchControl.children"
            )}`}</span>
          </div>
        }
      >
        <div className={styles.content}>
          <Field name="num_of_adults">
            {({ field: { value } }: any) => {
              return (
                <InputNumber
                  label={i18n.t("SearchControl.adults")}
                  min={0}
                  value={value}
                  onChange={handler.onChange("num_of_adults")}
                />
              );
            }}
          </Field>
          <Field name="num_of_children">
            {({ field: { value } }: any) => {
              return (
                <InputNumber
                  label={i18n.t("SearchControl.children")}
                  value={value}
                  min={0}
                  onChange={handler.onChange("num_of_children")}
                />
              );
            }}
          </Field>
        </div>
      </Dropdown>
    </>
  );
};
export default View;
