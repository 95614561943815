import styles from "./MenuHamburger.module.scss";
import cx from "classnames";
const View = ({ show = false, className, ...props }: any) => {
  return (
    <div className="flex items-center justify-center" {...props}>
      <label
        className={cx(styles.container, className, { [styles.show]: show })}
      >
        <div className={styles.checkmark}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </label>
    </div>
  );
};
export default View;
