import StoryParagraph from "./StoryParagraph";
import StorySlider from "./StorySlider";
import StoryImages from "./StoryImages";
import StoryTextButtonComponent from "./StoryTextButtonComponent";
import StoryTextImageLayout from "./StoryTextImageLayout";
import StoryTitleContent from "./StoryTitleContent";
import CommonButtonWithContent from "./CommonButtonWithContent";
export {
  StoryParagraph,
  StorySlider,
  StoryImages,
  StoryTextButtonComponent,
  StoryTextImageLayout,
  StoryTitleContent,
  CommonButtonWithContent,
};
