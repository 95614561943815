import React from "react";
import Swiper, {
  SwiperOptions,
  Navigation,
  Pagination,
  EffectCoverflow,
  Scrollbar,
} from "swiper";
Swiper.use([Navigation, Pagination, EffectCoverflow, Scrollbar]);
import _ from "lodash";
const useSwiper = (
  className: string,
  options: SwiperOptions,
  ...depts: any
) => {
  const ref: any = React.useRef({ checked: false, count: 0 });
  const [swiper, $swiper]: any = React.useState();
  _.assign(ref.current, { swiper, $swiper });
  const handler: any = {
    install: () => {
      const swiper: any = new Swiper(className, options);
      swiper.handler = handler;
      handler.checkDOM(swiper);
      ref.current.$swiper(swiper);
    },
    reInstall: () => {
      if (ref.current.swiper && _.isFunction(ref.current.swiper.destroy))
        ref.current.swiper.destroy();
      handler.resetCheckDOM();
      handler.install();
    },
    slideNext: () => {
      ref.current.swiper.slideNext();
    },
    slidePrev: () => {
      ref.current.swiper.slidePrev();
    },
    checkDOM: (swiper: any) => {
      // To help safari add className when swiper missing.
      if (swiper) {
        ref.current.checked = true;
        const currentIndex = _.get(ref.current.swiper, "realIndex", 2);
        // console.log(_.pick(swiper, ["activeIndex", "realIndex", "params"]));
        // console.log({ currentIndex });
        const prevIndex =
          currentIndex - 1 < 0 ? swiper.slides.length - 1 : currentIndex - 1;
        const nextIndex =
          currentIndex + 1 >= swiper.slides?.length ? 0 : currentIndex + 1;

        const currentSlide = document.querySelector(
          `${className} [data-swiper-slide-index="${currentIndex}"]`
        );
        const prevSlide = document.querySelector(
          `${className} [data-swiper-slide-index="${prevIndex}"]`
        );
        const nextSlide = document.querySelector(
          `${className} [data-swiper-slide-index="${nextIndex}"]`
        );
        if (currentSlide) {
          if (!currentSlide.classList.contains("swiper-slide-active")) {
            currentSlide.classList.add("swiper-slide-active");
          }
        }
        if (prevSlide) {
          if (!prevSlide.classList.contains("swiper-slide-prev")) {
            prevSlide.classList.add("swiper-slide-prev");
          }
        }
        if (nextSlide) {
          if (!nextSlide.classList.contains("swiper-slide-next")) {
            nextSlide.classList.add("swiper-slide-next");
          }
        }
      }
    },
    resetCheckDOM: () => {
      ref.current.checked = false;
      ref.current.count = 0;
    },
  };

  React.useEffect(() => {
    handler.install();
  }, []);
  React.useEffect(() => {
    handler.reInstall();
  }, _.castArray(depts));
  return ref.current.swiper;
};
export default useSwiper;
