import _ from "lodash";
import service from "@utils/apiService";
const formatter = {
  id: (item: any) => {
    return _.get(item, "id") || "";
  },
  name: (item: any) => {
    return _.get(item, "attributes.name") || "";
  },
  slug: (item: any) => {
    return _.get(item, "attributes.slug") || "";
  },
  image: (item: any) => {
    return _.isEmpty(item)
      ? null
      : {
          src:
            service?.strapiUrl(
              _.get(item, "data.attributes.url") ||
                _.get(item, "attributes.url")
            ) || "",
          alt:
            _.get(item, "data.attributes.alternativeText") ||
            _.get(item, "attributes.alternativeText") ||
            "",
          ext:
            _.get(item, "data.attributes.ext") ||
            _.get(item, "attributes.ext") ||
            "",
          mime:
            _.get(item, "data.attributes.mime") ||
            _.get(item, "attributes.mime") ||
            "",
        };
  },
  icon: (item: any) => {
    return _.isEmpty(item)
      ? null
      : {
          label: _.get(item, "label"),
          type: _.get(item, "iconType"),
          href: _.get(item, "iconLink") || "#",
        };
  },
  button: (item: any) => {
    return _.isEmpty(item)
      ? null
      : {
          label: _.get(item, "buttonName", ""),
          href: _.get(item, "buttonLink") || "#",
          type: _.get(item, "type", ""),
        };
  },
  bannerSlider: (item: any) => {
    return _.get(item, "attributes.bannerSlider") || {};
  },
  banner: (item: any) => {
    return _.get(item, "attributes.banner") || {};
  },
  hotelAddress: (item: any) => {
    return _.get(item, "attributes.hotelAddress") || {};
  },
  aboutUs: (item: any) => {
    return _.get(item, "attributes.aboutUs") || {};
  },
  visitRoom: (item: any) => {
    return _.get(item, "attributes.visitOurRooms") || {};
  },
  experiencingAmanaki: (item: any) => {
    return _.get(item, "attributes.experiencingAmanaki") || {};
  },
  exploreArts: (item: any) => {
    return _.get(item, "attributes.exploreArts") || {};
  },
  stories: (item: any) => {
    return _.get(item, "attributes.stories") || {};
  },
  offers: (item: any) => {
    return _.get(item, "attributes.offers") || {};
  },
  stayConnected: (item: any) => {
    return (
      _.get(item, "attributes.stayConnected") ||
      _.get(item, "stayConnected.attributes") ||
      {}
    );
  },
  // offer page
  exclusiveOffer: (item: any) => {
    return _.get(item, "attributes.exclusiveOffer") || {};
  },
  sliderSection: (item: any) => {
    return _.get(item, "attributes.sliderSection") || {};
  },
  restaurant: (item: any) => {
    return _.get(item, "attributes.restaurant") || {};
  },
  cuisine: (item: any) => {
    return _.get(item, "attributes.cuisine") || {};
  },
  restaurantInformation: (item: any) => {
    return _.get(item, "attributes.restaurantInformation") || {};
  },
  headChefSection: (item: any) => {
    return _.get(item, "attributes.headChefSection") || {};
  },
  exprienceTitle: (item: any) => {
    return _.get(item, "attributes.exprienceTitle") || {};
  },
  intro: (item: any) => {
    return _.get(item, "attributes.intro") || {};
  },
  additional: (item: any) => {
    return _.get(item, "attributes.additional") || {};
  },
  gallery: (item: any) => {
    return _.get(item, "attributes.gallery") || {};
  },
  wellbeingCentre: (item: any) => {
    return _.get(item, "attributes.wellbeingCentre") || {};
  },
  articles: (item: any) => {
    return (
      _.get(item, "attributes.articles.data") ||
      _.get(item, "attributes.articles") ||
      {}
    );
  },
  facilities: (item: any) => {
    return _.get(item, "attributes.facilities") || [];
  },
  facilitiesAndServices: (item: any) => {
    return _.get(item, "attributes.facilitiesAndServices") || [];
  },
  featured: (item: any) => {
    return _.get(item, "attributes.featured") || {};
  },
  explanation: (item: any) => {
    return _.get(item, "attributes.explanation") || {};
  },
  paragraphs: (item: any) => {
    return _.get(item, "attributes.paragraphs") || [];
  },
  amanakiLogo: (item: any) => {
    return _.get(item, "attributes.amanakiLogo") || {};
  },
  middleBanner: (item: any) => {
    return _.get(item, "attributes.middleBanner") || {};
  },
  images: (item: any) => {
    return (
      (_.has(item, "attributes.images.data")
        ? _.get(item, "attributes.images.data")
        : _.get(item, "attributes.images")) || []
    );
  },
  FAQs: (item: any) => {
    return (
      _.get(item, "attributes.FAQs") || _.get(item, "FAQs.attributes") || []
    );
  },
  featuredImages: (item: any) => {
    return _.get(item, "attributes.featuredImages") || [];
  },
  personalizationAndDetails: (item: any) => {
    return _.get(item, "attributes.personalizationAndDetails") || [];
  },
  requestForProposal: (item: any) => {
    return _.get(item, "attributes.requestForProposal") || [];
  },
  bannerFooter: (item: any) => {
    return _.get(item, "attributes.bannerFooter") || [];
  },
  introduce: (item: any) => {
    return _.get(item, "attributes.introduce") || [];
  },
  divider: (item: any) => {
    return _.get(item, "attributes.divider") || [];
  },
  recommendation: (item: any) => {
    return _.get(item, "attributes.recommendation") || [];
  },
  throughTheLens: (item: any) => {
    return _.get(item, "attributes.throughTheLens") || [];
  },
  learnDiscover: (item: any) => {
    return _.get(item, "attributes.learnDiscover") || [];
  },
  ourLocation: (item: any) => {
    return _.get(item, "attributes.ourLocation") || [];
  },
  spotlight: (item: any) => {
    return _.get(item, "attributes.spotlight") || {};
  },
  exhibition: (item: any) => {
    return _.get(item, "attributes.exhibition") || {};
  },
  ourJourney: (item: any) => {
    return _.get(item, "attributes.ourJourney") || {};
  },
  ourStories: (item: any) => {
    return _.get(item, "attributes.ourStories") || {};
  },
  ourHotel: (item: any) => {
    return _.get(item, "attributes.ourHotel") || {};
  },
  getInTouch: (item: any) => {
    return _.get(item, "attributes.getInTouch") || {};
  },
  sustainableSection: (item: any) => {
    return _.get(item, "attributes.sustainableSection") || {};
  },
  practiceSection: (item: any) => {
    return _.get(item, "attributes.practiceSection") || {};
  },
  dataSection: (item: any) => {
    return _.get(item, "attributes.dataSection") || {};
  },
  sustainableItems: (item: any) => {
    return _.get(item, "attributes.sustainableItems") || {};
  },
  partnershipSection: (item: any) => {
    return _.get(item, "attributes.partnershipSection") || {};
  },
  imageSection: (item: any) => {
    return _.get(item, "attributes.imageSection") || {};
  },
  heroSection: (item: any) => {
    return _.get(item, "attributes.heroSection") || {};
  },
  featuredAbout: (item: any) => {
    return _.get(item, "attributes.featuredAbout") || [];
  },
  coreValues: (item: any) => {
    return _.get(item, "attributes.coreValues") || [];
  },
  peopleAndCulture: (item: any) => {
    return _.get(item, "attributes.peopleAndCulture") || {};
  },
  humans: (item: any) => {
    return _.get(item, "attributes.humans") || {};
  },
  works: (item: any) => {
    return _.get(item, "attributes.works") || {};
  },
  legalNotice: (item: any) => {
    return _.get(item, "attributes.legalNotice") || {};
  },
  collection: (item: any) => {
    return _.get(item, "attributes.collection") || {};
  },
  paragraph: (item: any) => {
    return _.get(item, "attributes.paragraph") || {};
  },
  experience: (item: any) => {
    return _.get(item, "attributes.experience") || {};
  },
  metatags: (item: any) => {
    return _.get(item, "metatags") || _.get(item, "attributes.metatags") || {};
  },
};
export default formatter;
