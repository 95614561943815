import React, { createContext, useContext, ReactNode } from "react";
import { AppStrapiContext, AppStrapiProps } from "@type/provider/Strapi";
import { findComp, renderComp } from "@strapiCMS/util";
import _ from "lodash";
export const StrapiContext = createContext<AppStrapiContext>({});

interface TypeUseStrapiContext {
  (): AppStrapiContext;
}

const StrapiProvider = ({ children }: AppStrapiProps): ReactNode => {
  const str = {
    renderComp,
    findComp,
  };
  return (
    <StrapiContext.Provider value={{ str }}>{children}</StrapiContext.Provider>
  );
};
export const useStrapiContext: TypeUseStrapiContext = () => {
  return useContext(StrapiContext);
};
export default StrapiProvider;
