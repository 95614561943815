import { usePageContext } from "@providers/PageProvider";
import TextTitleBullet from "@components/Common/TextTitleBullet";
import Markdown from "@components/Markdown";
import styles from "./TextTitle.module.scss";
import renderPropsComposer from "@utils/renderPropsComposer";
import cx from "classnames";
import _ from "lodash";
const View = ({ title, description }: any) => {
  const { pageId } = usePageContext();
  return renderPropsComposer(
    {
      matcher: (pageId: string) => pageId === "story-article-detail",
      render: () => (
        <div className={cx("inner-article", styles.section)}>
          <h1 className={cx("ama-title text-center", styles.title)}>{title}</h1>
          <div className={cx("ama-content", styles.content)}>
            <Markdown content={description} />
            {/* {description} */}
          </div>
        </div>
      ),
    },
    {
      matcher: (pageId: string) => pageId === "offer-detail",
      render: () => <TextTitleBullet title={title} description={description} />,
    },
    {
      matcher: "default",
      render: () => {
        console.warn("Cần Define Thêm Component UI Cho Page: ", pageId);
        return <></>;
      },
    }
  )(pageId);
};
export default View;
