import React from "react";
import gstyles from "@gstyles/index";
import styles from "./CustomInputStyle.module.scss";
import { formatDateDisplay, formatFullTimeDisplay } from "@utils/dateFormatter";
import cx from "classnames";
const View = React.forwardRef(
  (
    {
      onClick,
      date,
      field,
      placeholder = "",
      format = "default",
      className,
      ariaLabel = "amanaki input",
      border = false,
    }: any,
    _ref: any
  ) => {
    const handler: any = {
      default: (date: any) => {
        field.value = date ? formatDateDisplay(date) : "";
      },
      fullDate: (date: any) => {
        field.value = date ? formatFullTimeDisplay(date) : "";
      },
    };

    if (handler[format]) {
      handler[format](date);
    }
    return (
      <div
        className={cx(styles.input, className, { [styles.border]: border })}
        onClick={onClick}
      >
        {gstyles.icons({
          className: styles.prefix,
          name: "calendar-outline",
          alt: "calendar",
          size: 22,
          color: "#2e281f",
        })}
        <input
          {...field}
          aria-label={ariaLabel}
          placeholder={placeholder}
          readOnly
        />
        <span className={styles.bar} />
      </div>
    );
  }
);
View.displayName = "CustomInputStyle";
export default View;
