const View = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5617_33832)">
        <path
          d="M14.2969 12C14.2969 13.2686 13.2686 14.2969 12 14.2969C10.7314 14.2969 9.70312 13.2686 9.70312 12C9.70312 10.7314 10.7314 9.70312 12 9.70312C13.2686 9.70312 14.2969 10.7314 14.2969 12Z"
          fill="#F7EEDF"
        />
        <path
          d="M17.3723 7.93573C17.2619 7.63654 17.0858 7.36572 16.8569 7.14343C16.6346 6.91455 16.364 6.7384 16.0646 6.62799C15.8218 6.53369 15.457 6.42145 14.7852 6.39087C14.0585 6.35773 13.8406 6.35059 12.0007 6.35059C10.1607 6.35059 9.94281 6.35754 9.21625 6.39069C8.54443 6.42145 8.1795 6.53369 7.93689 6.62799C7.63751 6.7384 7.3667 6.91455 7.14459 7.14343C6.91571 7.36572 6.73956 7.63635 6.62897 7.93573C6.53467 8.17853 6.42242 8.54346 6.39185 9.21527C6.3587 9.94183 6.35156 10.1597 6.35156 11.9998C6.35156 13.8396 6.3587 14.0575 6.39185 14.7842C6.42242 15.4561 6.53467 15.8208 6.62897 16.0636C6.73956 16.363 6.91553 16.6336 7.14441 16.8559C7.3667 17.0848 7.63733 17.2609 7.93671 17.3713C8.1795 17.4658 8.54443 17.5781 9.21625 17.6086C9.94281 17.6418 10.1605 17.6487 12.0005 17.6487C13.8408 17.6487 14.0587 17.6418 14.785 17.6086C15.4568 17.5781 15.8218 17.4658 16.0646 17.3713C16.6655 17.1395 17.1405 16.6646 17.3723 16.0636C17.4666 15.8208 17.5789 15.4561 17.6096 14.7842C17.6428 14.0575 17.6497 13.8396 17.6497 11.9998C17.6497 10.1597 17.6428 9.94183 17.6096 9.21527C17.579 8.54346 17.4668 8.17853 17.3723 7.93573ZM12.0007 15.5379C10.0464 15.5379 8.46222 13.9539 8.46222 11.9996C8.46222 10.0453 10.0464 8.46124 12.0007 8.46124C13.9548 8.46124 15.5391 10.0453 15.5391 11.9996C15.5391 13.9539 13.9548 15.5379 12.0007 15.5379ZM15.679 9.14825C15.2223 9.14825 14.8521 8.77802 14.8521 8.32135C14.8521 7.86469 15.2223 7.49445 15.679 7.49445C16.1356 7.49445 16.5059 7.86469 16.5059 8.32135C16.5057 8.77802 16.1356 9.14825 15.679 9.14825Z"
          fill="#F7EEDF"
        />
        <path
          d="M12 0C5.3736 0 0 5.3736 0 12C0 18.6264 5.3736 24 12 24C18.6264 24 24 18.6264 24 12C24 5.3736 18.6264 0 12 0ZM18.8491 14.8409C18.8157 15.5744 18.6991 16.0752 18.5288 16.5135C18.1708 17.4391 17.4391 18.1708 16.5135 18.5288C16.0754 18.6991 15.5744 18.8156 14.8411 18.8491C14.1063 18.8826 13.8715 18.8906 12.0002 18.8906C10.1287 18.8906 9.8941 18.8826 9.15912 18.8491C8.42578 18.8156 7.9248 18.6991 7.48663 18.5288C7.02667 18.3558 6.61029 18.0846 6.26605 17.7339C5.91559 17.3899 5.64441 16.9733 5.47137 16.5135C5.30109 16.0754 5.18445 15.5744 5.15112 14.8411C5.11725 14.1061 5.10938 13.8713 5.10938 12C5.10938 10.1287 5.11725 9.89392 5.15094 9.15912C5.18427 8.4256 5.30072 7.9248 5.47101 7.48645C5.64404 7.02667 5.91541 6.61011 6.26605 6.26605C6.61011 5.91541 7.02667 5.64423 7.48645 5.47119C7.9248 5.3009 8.4256 5.18445 9.15912 5.15094C9.89392 5.11743 10.1287 5.10938 12 5.10938C13.8713 5.10938 14.1061 5.11743 14.8409 5.15112C15.5744 5.18445 16.0752 5.3009 16.5135 5.47101C16.9733 5.64404 17.3899 5.91541 17.7341 6.26605C18.0846 6.61029 18.356 7.02667 18.5288 7.48645C18.6993 7.9248 18.8157 8.4256 18.8492 9.15912C18.8828 9.89392 18.8906 10.1287 18.8906 12C18.8906 13.8713 18.8828 14.1061 18.8491 14.8409Z"
          fill="#F7EEDF"
        />
      </g>
      <defs>
        <clipPath id="clip0_5617_33832">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default View;
