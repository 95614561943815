import { createContext, useEffect, useState, useContext, useRef } from "react";
import { AppLocomotiveContext } from "@type/provider/Locomotive";
import _ from "lodash";
interface TypeUseLocomotiveContext {
  (): AppLocomotiveContext;
}
export const SmoothScrollContext = createContext({
  scroll: null,
});
export const SmoothScrollProvider = ({ children, options }: any) => {
  const [scroll, $scroll]: any = useState(null);
  const ref: any = useRef({});
  _.assign(ref.current, { scroll });
  useEffect(() => {
    if (!scroll) {
      (async () => {
        try {
          const LocomotiveScroll = (await import("locomotive-scroll")).default;
          const element = document.querySelector("[data-scroll-container]");
          const _scroll = new LocomotiveScroll({
            el: element ?? null,
            ...options,
          });
          $scroll(_scroll);

          // Kiểm tra khi DOM change thì update lại scroll.
          if (element && !!_scroll)
            new ResizeObserver(() => _scroll.update()).observe(element);
        } catch (error) {
          throw Error(`[SmoothScrollProvider]: ${error}`);
        }
      })();
    }
    document.addEventListener("lazyloaded", function () {
      scroll.update();
    });
    return () => {
      scroll && scroll.destroy();
    };
  }, [scroll]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SmoothScrollContext.Provider value={{ scroll }}>
      {children}
    </SmoothScrollContext.Provider>
  );
};
export const useLocomotiveContext: TypeUseLocomotiveContext = () => {
  return useContext(SmoothScrollContext);
};

SmoothScrollContext.displayName = "SmoothScrollContext";
SmoothScrollProvider.displayName = "SmoothScrollProvider";

export default SmoothScrollProvider;
