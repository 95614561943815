const path = require("path");
import _ from "lodash";
import i18n from "@translations/i18n";

const SEP: string = "/";
const isExternalUrl = (val: string) => {
  return `${val}`.substring(0, 4) === "http";
};
class LocaleConfig {
  config: any;
  constructor(config: any) {
    this.config = config;
  }

  get(att: string) {
    return _.get(this.config, att);
  }
  set(att: string, value: any) {
    return _.set(this.config, att, value);
  }
  setLanguage(value: any) {
    this.set("langSlug", value);
  }
  langSlug(url: string = ``) {
    const langPrefix = `${this.get("langSlug")}`;
    if (langPrefix && !_.startsWith(url, langPrefix)) {
      return path.join(langPrefix, url);
    }
    return path.join("", url);
  }
  withLangPrefix(url: string) {
    if (isExternalUrl(url)) return url;
    const paths = _.compact(_.split(url, SEP));
    const localeLang = _.first(paths);
    if (_.get(localeMap, `${localeLang}`)) {
      url = path.join(...paths.splice(1));
      return path.join(SEP, this.langSlug(url));
    }
    return path.join(SEP, this.langSlug(url));
  }

  matchRoute(url: string) {
    const paths = _.compact(_.split(url, SEP));
    const localeLang = _.first(paths);
    return localeLang === this.get("lang");
  }
}
const configLocales = [
  {
    name: "en",
    locale: "en-US",
    lang: "en",
    langSlug: `en/`,
    country: "US",
  },
  {
    name: "vi",
    locale: "vi-VN",
    lang: "vi",
    langSlug: `vi/`,
    country: "VN",
  },
  {
    name: "default",
    locale: "en-US",
    lang: "en",
    langSlug: "",
    country: "US"
  },
  // {
  //   name: "default",
  //   locale: "vi-VN",
  //   lang: "vi",
  //   langSlug: "",
  //   country: "VN",
  // },
].map((item) => new LocaleConfig(item));

export const localeMap = _.keyBy(configLocales, (item) => item.get("lang"));

const getDefaultLocaleConfig = _.memoize(() => {
  for (const localeConfig of configLocales) {
    if (localeConfig.get("name") === "default") {
      return localeConfig;
    }
  }
  return configLocales[0];
});

export const getCurrentLocaleConfig = () => {
  const locale = i18n.language;
  // console.log({ locale });
  for (const localeConfig of configLocales) {
    if (_.isEqual(localeConfig.get("lang"), locale)) {
      return localeConfig;
    }
  }
  return getDefaultLocaleConfig();
};

// export const localeStore: any = new LocaleStore({ lang: "" });
