const View = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1746 10.2298C15.3843 10.05 15.6999 10.0743 15.8796 10.284C16.0593 10.4937 16.0351 10.8093 15.8254 10.989L12.3254 13.989C12.1382 14.1495 11.8619 14.1495 11.6746 13.989L8.17461 10.989C7.96495 10.8093 7.94067 10.4937 8.12038 10.284C8.30009 10.0743 8.61574 10.05 8.8254 10.2298L12 12.9508L15.1746 10.2298Z"
        fill="#AF915E"
      />
    </svg>
  );
};
export default View;
