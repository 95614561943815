import _ from "lodash";
import service from "@utils/apiService";
const formatter = {
  id: (item: any) => {
    return _.get(item, "id") || "";
  },
  title: (item: any) => {
    return _.get(item, "attributes.title") || "";
  },
  name: (item: any) => {
    return _.get(item, "attributes.name") || "";
  },
  description: (item: any) => {
    return _.get(item, "attributes.description") || "";
  },
  phone: (item: any) => {
    return _.get(item, "attributes.phone") || "";
  },
  website: (item: any) => {
    return _.get(item, "attributes.website") || "";
  },
  mapLink: (item: any) => {
    return _.get(item, "attributes.mapLink") || "";
  },
  distance: (item: any) => {
    return _.get(item, "attributes.distance") || "";
  },
  publishedAt: (item: any) => {
    return _.get(item, "attributes.publishedAt") || "";
  },
  slug: (item: any) => {
    return _.get(item, "attributes.slug") || "";
  },
  category: (item: any) => {
    const data = _.get(item, "attributes.category.data") || "";
    return {
      name: _.get(data, "attributes.name"),
      slug: _.get(data, "attributes.slug"),
    };
  },
  thumbnail: (item: any) => {
    return (
      service.strapiUrl(
        _.get(item, "attributes.thumbnail.data.attributes.url")
      ) || ""
    );
  },
  image: (item: any) => {
    return {
      src: service?.strapiUrl(_.get(item, "data.attributes.url")) || "",
      alt: _.get(item, "data.attributes.alternativeText") || "",
    };
  },
};
export default formatter;
