import React from "react";
import _ from "lodash";
const useDocumentClick = (callback: any, type = "document") => {
  const handler = (event: any) => {
    if (_.isFunction(callback)) callback(event);
  };
  const _handler: any = {
    document: {
      add: () => {
        document.addEventListener("click", handler, true);
      },
      remove: () => {
        document.removeEventListener("click", handler, true);
      },
    },
    body: {
      add: () => {
        document.body.addEventListener("click", handler, true);
      },
      remove: () => {
        document.body.removeEventListener("click", handler, true);
      },
    },
  };
  React.useEffect(() => {
    _handler[type].add();
    // document.addEventListener("click", handler, true);
    return () => {
      _handler[type].remove();
      // document.removeEventListener("click", handler, true);
    };
  });
};
export default useDocumentClick;
