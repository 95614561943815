import React, { createContext, useContext, ReactNode } from "react";
import { AppBookingContext } from "@type/provider/Booking";
import { useRouteContext } from "./RouteProvider";
import _ from "lodash";
import { HOTEL_CODES } from "@utils/constant";
import helper from "@utils/helper";
export const BookingContext = createContext<AppBookingContext>({
  booking: {
    open: () => {},
  },
});

interface TypeUseBookingContext {
  (): AppBookingContext;
}

const BookingProvider = ({ children }: AppBookingContext): ReactNode => {
  const { router } = useRouteContext();
  const booking = {
    open: ({ hotel = "amanaki-thao-dien", ...payload }: any = {}) => {
      const hotel_code = HOTEL_CODES[hotel];
      const url = helper.getBookingLink({ ...payload, hotel_code });
      router.open(url);
    },
  };
  return (
    <BookingContext.Provider value={{ booking }}>
      {children}
    </BookingContext.Provider>
  );
};
export const useBookingContext: TypeUseBookingContext = () => {
  return useContext(BookingContext);
};
export default BookingProvider;
