import React from "react";

import Paragraph from "./components/Paragraph";

import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkBreaks from "remark-breaks";
// import helper from "@utils/helper";
import cx from "classnames";
import _ from "lodash";

const DEFAULT_COMPONENTS = {
  p: Paragraph,
};

const View = ({
  content,
  components = {},
  className,
  ...props
}: {
  content: any;
  components?: any;
  className?: string;
  [key: string]: any;
}) => {
  const comps = { ...DEFAULT_COMPONENTS, ...components };
  return (
    <div className={cx("relative", className)}>
      <ReactMarkdown
        {...props}
        components={comps}
        remarkPlugins={[remarkBreaks]}
        rehypePlugins={[rehypeRaw]}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default View;
