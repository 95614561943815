import TextTitle from "@components/Article/TextTitle";
import _ from "lodash";
const View = (props: any) => {
  return (
    <TextTitle
      title={_.get(props, "title")}
      description={_.get(props, "content")}
    />
  );
};

View.strapiName = "story.title-content";

export default View;
