import _ from "lodash";
import service from "@utils/apiService";
const formatter = {
  id: (item: any) => {
    return _.get(item, "id") || "";
  },
  title: (item: any) => {
    return _.get(item, "attributes.title") || "";
  },
  amenities: (item: any) => {
    return _.get(item, "attributes.amenities") || "";
  },
  bedInfo: (item: any) => {
    return _.get(item, "attributes.bedInfo") || "";
  },
  capacity: (item: any) => {
    return _.get(item, "attributes.capacity") || "";
  },
  category: (item: any) => {
    return _.get(item, "attributes.category") || "";
  },
  checkIn: (item: any) => {
    return _.get(item, "attributes.checkIn") || "";
  },
  checkOut: (item: any) => {
    return _.get(item, "attributes.checkOut") || "";
  },
  description: (item: any) => {
    return _.get(item, "attributes.description") || "";
  },
  features: (item: any) => {
    return _.get(item, "attributes.features") || "";
  },
  location: (item: any) => {
    return _.get(item, "attributes.location") || "";
  },
  name: (item: any) => {
    return _.get(item, "attributes.name") || "";
  },
  publishedAt: (item: any) => {
    return _.get(item, "attributes.publishedAt") || "";
  },
  size: (item: any) => {
    return _.get(item, "attributes.size") || "";
  },
  type: (item: any) => {
    return _.get(item, "attributes.type") || "";
  },
  slug: (item: any) => {
    return _.get(item, "attributes.slug") || "";
  },

  thumbnail: (item: any) => {
    return (
      service.strapiUrl(
        _.get(item, "attributes.thumbnail.data.attributes.url")
      ) || ""
    );
  },
  image: (item: any) => {
    return {
      src: service?.strapiUrl(_.get(item, "data.attributes.url")) || "",
      alt: _.get(item, "data.attributes.alternativeText") || "",
    };
  },
};
export default formatter;
