import React from "react";
import { useModalContext } from "@providers/ModalProvider";
import { useRouteContext } from "@providers/RouteProvider";
import Modal from "@components/Modal";
import styles from "./MenuModal.module.scss";
import MenuHamburger from "@components/MenuHamburger";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import _ from "lodash";

const View = ({ NAVS, MENU_GROUP, hotel }: any) => {
  const { router } = useRouteContext();
  const [_isSwitch, $isSwitch] = React.useState(false);
  const [currentNav, $currentNav] = React.useState("");
  const [show, $show] = React.useState(false);
  const { modalService } = useModalContext();
  // const { i18n } = useTranslationContext();
  const handler = {
    onClickNav: (nav: any) => {
      const link = _.get(nav, "href", "");
      if (link) {
        router.push(link);
      }
      if (nav && nav.label && nav.label !== currentNav) {
        $isSwitch(true);
        _.debounce(() => {
          $currentNav(nav.label);
          $isSwitch(false);
        }, 400)();
      }
    },
    reset: () => {
      $currentNav("");
      $isSwitch(false);
    },
    onClose: () => modalService.removeDOM(400, handler.reset),
  };
  React.useEffect(() => {
    $show(!!modalService.visible);
  }, [_.get(modalService, "visible")]);
  // const current = _.find(NAVS, (nav) => nav.label === currentNav);
  // const data = _.get(current, "children", []);
  // const image = _.get(current, "src", "");
  return (
    <Modal
      style={{
        content: { inset: 0, transform: "initial", marginRight: 0, padding: 0 },
      }}
      isOpen={modalService.visible}
      // onRequestClose={handler.onClose}
    >
      <div className={styles.menuIcon}>
        <MenuHamburger show={show} onClick={handler.onClose} />
      </div>
      <Desktop NAVS={NAVS} MENU_GROUP={MENU_GROUP} hotel={hotel} />
      <Mobile NAVS={NAVS} MENU_GROUP={MENU_GROUP} hotel={hotel} />
    </Modal>
  );
};
export default View;
