import Swiper from "@components/Article/Gallery/Swiper";
import getter from "@strapiCMS/getter";
import _ from "lodash";
const View = (props: any) => {
  const images = _.map(_.get(props, "images.data", []), (item) => ({
    src: getter.url(item),
    alt: getter.alt(item),
  }));
  return <Swiper images={images} />;
};

View.strapiName = "story.slider";

export default View;
