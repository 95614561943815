// src/context/state.js
import React from "react";
import { AppWrapperProps } from "@type/provider/Wrapper";
import RefProvider from "./RefProvider";
import PageProvider from "./PageProvider";
import RouteProvider from "./RouteProvider";
import TranslationProvider from "./TranslationProvider";
import BookingProvider from "./BookingProvider";
import StrapiProvider from "./StrapiProvider";

import _ from "lodash";

export default function Wrapper({ children, ...props }: AppWrapperProps) {
  const value = [
    RefProvider,
    PageProvider,
    TranslationProvider,
    RouteProvider,
    BookingProvider,
    StrapiProvider,
  ];
  return value.reduceRight((acc, Component: any) => {
    return React.createElement(Component, props, acc);
  }, children);
}
