const View = ({ color = "#F7EEDF", ...props }: any) => {
  return (
    <svg {...props} width="51" height="14" viewBox="0 0 51 14" fill="none">
      <path
        d="M47.9402 7.95866L0.920287 7.95866C0.518212 7.95866 0.192265 7.6322 0.192265 7.22949C0.192265 6.82678 0.518212 6.50032 0.920287 6.50032L47.9402 6.50033L43.3588 1.91176C43.0745 1.627 43.0745 1.16532 43.3588 0.880563C43.6431 0.595806 44.1041 0.595806 44.3884 0.880563L50.2125 6.7139C50.4969 6.99865 50.4969 7.46034 50.2125 7.74509L44.3884 13.5784C44.1041 13.8632 43.6431 13.8632 43.3588 13.5784C43.0745 13.2937 43.0745 12.832 43.3588 12.5472L47.9402 7.95866Z"
        fill={color}
      />
    </svg>
  );
};

export default View;
