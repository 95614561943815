import styles from "./Button.module.scss";
import cx from "classnames";
import ShapeArrow from "@components/Icons/ShapeArrow";
import renderPropsComposer from "@utils/renderPropsComposer";
import _ from "lodash";
const View = ({
  children,
  label,
  labelMobile,
  className,
  type = "default",
  dark = false,
  disabled = false,
  color = "",
  prefix,
  suffix,
  onClick,
  textWhite = false,
  ariaLabel = "amanaki button",
  ...props
}: {
  children?: React.ReactNode;
  label?: string | React.ReactNode;
  className?: string;
  type?: "fold" | "default" | "primary" | "text" | "overlay" | "icon";
  dark?: boolean;
  color?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  [key: string]: any;
}) => {
  if (_.isEmpty(label) && _.isEmpty(children)) return null;
  return (
    <button
      aria-label={ariaLabel}
      className={cx(
        "ama-label",
        styles.button,
        className,
        {
          [styles[type]]: type,
          [styles["dark"]]: dark,
          [styles.disabled]: !!disabled,
          [styles.textWhite]: textWhite,
          [styles.aminText]: type === "default" || type === "primary",
        },
        styles[color]
      )}
      onClick={(e: any) => onClick && onClick(e)}
      {...props}
    >
      {/* <div className={styles.corner}>
        <div className={styles.cornerTriangle}></div>
      </div> */}
      {prefix && prefix}

      <div className={styles.hiddenText}>
        <>
          {children && children}
          {label && label}
        </>
      </div>

      <div className={styles.displayText}>
        <>
          {children && children}
          {label && label}
        </>
      </div>
      {suffix &&
        renderPropsComposer(
          {
            matcher: (props: any) => props.suffix === "shape-arrow",
            render: () => (
              <div className={cx("ml-4", styles.suffix)}>
                <ShapeArrow />
                {/* <ShapeShortArrow /> */}
              </div>
            ),
          },
          {
            matcher: "default",
            render: (props: any) => props.suffix,
          }
        )({ suffix })}
    </button>
  );
};
export default View;
