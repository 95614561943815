import * as comps from "./components";
import _ from "lodash";
export const findComp = (item: object[]) => {
  const entries = comps;
  const Component: any = _.find(entries, (comp: any) => {
    return _.get(comp, "strapiName") === _.get(item, "__component");
  });
  return _.isFunction(Component) ? Component(item) : null;
  // comps
};
export const renderComp = (data: any) => {
  return _.compact(_.map(_.castArray(data), findComp));
};
