import React from "react";
import gstyles from "@gstyles/index";
import styles from "./InputNumber.module.scss";
import _ from "lodash";
const View = ({ label, min, max, value, onChange }: any) => {
  const [number, $number] = React.useState(value);
  const handler = {
    plus: () => {
      const newNumber = number + 1;
      if (!_.isNumber(max) || (_.isNumber(max) && newNumber <= max)) {
        $number(newNumber);
        handler.onChange(newNumber);
      }
    },
    minus: () => {
      const newNumber = number - 1;
      if (!_.isNumber(min) || (_.isNumber(min) && newNumber >= min)) {
        $number(newNumber);
        handler.onChange(newNumber);
      }
    },
    onChange: (value: number) => {
      onChange && onChange(value);
    },
  };
  return (
    <div className={styles.box}>
      {!!label && <label>{label}</label>}
      <div className={styles.action}>
        <div className={styles.button} onClick={handler.minus}>
          {gstyles.icons({
            name: "minus-outline",
            size: 24,
            color: "white",
            alt: "minus",
          })}
        </div>
        <div className={styles.number}>{number}</div>
        <div className={styles.button} onClick={handler.plus}>
          {gstyles.icons({
            name: "plus-outline",
            size: 24,
            color: "white",
            alt: "plus",
          })}
        </div>
      </div>
    </div>
  );
};
export default View;
