const View = ({ color = "black", size = 52, ...props }: any) => {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 52 52" fill="none">
      <path
        d="M25.9993 24.4678L34.9833 15.4838C35.4064 15.0607 36.0923 15.0607 36.5154 15.4838C36.9384 15.9069 36.9384 16.5928 36.5154 17.0159L27.5314 25.9998L36.5154 34.9838C36.9384 35.4069 36.9384 36.0928 36.5154 36.5159C36.0923 36.9389 35.4064 36.9389 34.9833 36.5159L25.9993 27.5319L17.0154 36.5159C16.5923 36.9389 15.9064 36.9389 15.4833 36.5159C15.0602 36.0928 15.0602 35.4069 15.4833 34.9838L24.4673 25.9998L15.4833 17.0159C15.0602 16.5928 15.0602 15.9069 15.4833 15.4838C15.9064 15.0607 16.5923 15.0607 17.0154 15.4838L25.9993 24.4678Z"
        fill={color}
      />
    </svg>
  );
};
export default View;
