import Markdown from "@components/Markdown";
import styles from "./Paragraph.module.scss";
import cx from "classnames";
const Components = {
  p: ({ children }: any) => {
    return (
      <div className={cx("ama-content", styles.paragraph)}> {children}</div>
    );
  },
};
const View = ({ text }: any) => {
  return (
    <div className={cx("inner-article", styles.section)}>
      <Markdown components={Components} content={text} />
    </div>
  );
};
export default View;
