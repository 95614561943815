import Image from "@components/Image";
import styles from "./Gallery.module.scss";
import cx from "classnames";
import renderPropsComposer from "@utils/renderPropsComposer";
import _ from "lodash";
const View = ({ images }: any) => {
  return (
    <div className={styles.section}>
      {renderPropsComposer(
        {
          matcher: ({ key }: any) => key === "half",
          render: ({ data }: any) => (
            <div className={cx("inner", styles.twoCols)}>
              {_.map(data, (item, index) => (
                <Image
                  wrapperProps={{ className: styles.image }}
                  key={`two_${index}`}
                  {...item}
                />
              ))}
            </div>
          ),
        },
        {
          matcher: ({ key }: any) => key === "third",
          render: ({ data }: any) => (
            <div className={cx("inner", styles.threeCols)}>
              {_.map(data, (item, index) => (
                <Image
                  wrapperProps={{ className: styles.image }}
                  key={`three_${index}`}
                  {...item}
                />
              ))}
            </div>
          ),
        }
      )({
        data: images,
        key: cx({
          half: images?.length === 2,
          third: images?.length === 3,
        }),
      })}
    </div>
  );
};
export default View;
