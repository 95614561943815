import _ from "lodash";
import service from "@utils/apiService";
const formatter = {
  image: (item: any) => {
    return _.isEmpty(item)
      ? null
      : {
          src: service.strapiUrl(_.get(item, "attributes.url")) || "",
          alt: _.get(item, "attributes.alternativeText") || "",
        };
  },
  button: (item: any) => {
    return _.isEmpty(item)
      ? null
      : {
          label: _.get(item, "buttonName", ""),
          href: _.get(item, "buttonLink") || "#",
          type: _.get(item, "type", ""),
        };
  },
  title: (item: any) => {
    return _.get(item, "attributes.title") || "";
  },
  description: (item: any) => {
    return _.get(item, "attributes.description") || "";
  },
  url: (item: any) => {
    return service.strapiUrl(_.get(item, "attributes.url")) || "";
  },
  alt: (item: any) => {
    return _.get(item, "attributes.alternativeText") || "";
  },
};
export default formatter;
