import React from "react";
import styles from "./Select.module.scss";
import cx from "classnames";
import _ from "lodash";
import { Field } from "formik";
const View = ({
  name,
  validate,
  fast,
  placeholder,
  required,
  options,
  className,
  optionCapitalize = false,
  placeholderProps = {},
  optionProps = {},
  boxOptionProps = {},
  inputProps = {},
  onChange,
  onBlur,
  theme = "light",
  prefix = "",
  placeholderNoneAmin = false,
  type = "", // "placeholderNoneAmin" | 'border'
}: any) => {
  const [show, $show] = React.useState(false);
  const ref: any = React.useRef({});
  const selectRef: any = React.useRef({});
  _.assign(ref.current, {
    show,
    $show,
  });
  const onChangeHandle = (event: any, option?: any) => {
    const value = option
      ? _.get(option, "value", "")
      : _.get(event, "target.value", "");

    ref.current.form && ref.current.form.setFieldValue(name, value);
    onChange && onChange(value);
  };
  const onSelect = (event: any, option?: any) => {
    ref.current.$show(false);
    onChangeHandle(event, option);
  };
  const onBlurHandle = (e: any) => {
    ref.current.$show(false);
    ref.current.form && ref.current.form.setFieldTouched(name);
    onBlur && onBlur(e);
  };

  return (
    <Field name={name} validate={validate} fast={fast}>
      {({ field: { value }, form }: any) => {
        ref.current.form = form;
        const inputValue = _.get(
          _.find(options, (opt) => opt.value === value),
          "label",
          ""
        );
        return (
          <div
            className={cx(
              styles.group,
              styles.placeholderNoneAmin,
              {
                [styles.active]: !!ref.current.show,
                [styles.valid]: inputValue !== "",
                [styles.dark]: theme === "dark",
              },
              className,
              styles[type]
            )}
            ref={selectRef}
          >
            <div className="flex">
              {!!prefix && prefix}
              <input
                type="text"
                onFocus={() => {
                  ref.current.$show(true);
                }}
                value={inputValue}
                onChange={(e) => onChangeHandle(e)}
                onBlur={(e) => {
                  setTimeout(() => {
                    onBlurHandle(e);
                  }, 50);
                }}
                aria-label={name}
                readOnly
                className={cx(
                  { [styles.dark]: theme === "dark" },
                  inputProps.className
                )}
              />
            </div>
            <span className={styles.bar} />
            {!inputValue && (
              <label
                className={cx(
                  {
                    [styles.dark]: theme === "dark",
                    [styles.placeholderNoneAmin]: placeholderNoneAmin,
                  },
                  styles[type],
                  placeholderProps.className
                )}
              >
                {placeholder}
                {required && <span className={styles.required}>*</span>}
              </label>
            )}
            <div className={cx(styles.options)}>
              {_.map(options, (option: any, index: number) => {
                return (
                  <div
                    key={`option_${name}_${index}`}
                    className={cx(styles.boxOption, boxOptionProps.className)}
                  >
                    <div
                      {...optionProps}
                      className={cx(
                        styles.option,
                        {
                          [styles.active]:
                            option.value === ref.current.form.values[name],
                        },
                        optionProps.className,
                        { [styles.optionCapitalize]: optionCapitalize }
                      )}
                      onClick={(e) => {
                        onSelect(e, option);
                      }}
                    >
                      <label>{option.label}</label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    </Field>
  );
};
export default View;
