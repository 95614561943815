import moment from "moment";
import i18n from "@translations/i18n";
import _ from "lodash";
export const formatDateDisplay = (date: string | Date, format?: string) => {
  return !!date
    ? moment(date, format)
        .locale(i18n.language)
        .format(`${i18n.t("DateFormat.Default")}`)
    : "";
};
export const formatFullDateDisplay = (date: string | Date) => {
  return moment(date).format(`${i18n.t("DateFormat.FullDisplay")}`);
};
export const getMonth = (date: string | Date, format: string = "short") => {
  const handler: any = {
    short: () => moment(date).format("MMM"),
    full: () => moment(date).format("MMMM"),
  };
  if (_.has(handler, format)) return handler[format]();
  return "";
};
export const getYear = (date: string | Date) => {
  return moment(date).year();
};
// export const formatTimeDisplay = (date: string | Date) => {
//   return moment(date).format("LT");
// };
export const formatFullTimeDisplay = (date: string | Date) => {
  return moment(date).format("LTS");
};
export const formatTimeDinning = (date: string | Date) => {
  return moment(date, "HH:mm:ss").format("HH:mm");
};
export const formatHeaderCalendar = (date: string | Date) => {
  return moment(date).format("MMM YYYY");
};
export const formatDateMonth = (date: string | Date) => {
  return moment(date).locale(i18n.language).format("MMMM DD");
};
export const formatPayload = (date: string | Date) => {
  return moment(date).format("YYYY-MM-DD");
};
export const formatTimeDisplay = (time: string | Date, format?: string) => {
  return !!time
    ? moment(time, format)
        .locale(i18n.language)
        .format(`${i18n.t("DateFormat.Time")}`)
    : "";
};
