import { useTranslationContext } from "@providers/TranslationProvider";
import cx from "classnames";
import map from "lodash/map";
import Link from "@components/Link";
import { useEffect, useState } from "react";
import Down from "@components/Icons/Down";
import Facebook from "@components/Icons/Facebook";
import Instagram from "@components/Icons/Instagram";
import LogoVertical from "@components/Logo/VerticalYellow";
import Expand from "@components/Expand";
import Icons from "@gstyles/styleguide/icons";
import styles from "./Footer2.module.scss";
import { useRouteContext } from "@providers/RouteProvider";
import { useLocomotiveContext } from "@providers/LocomotiveProvider";

interface Props {
  hideSocialIcons?: boolean;
  data?: {
    name?: string;
    address?: string;
    email?: string;
    phone?: string;
    facebook?: string;
    instagram?: string;
  };
}

function Footer(props: Props) {
  const { scroll } = useLocomotiveContext();
  const { i18n } = useTranslationContext();
  const { hideSocialIcons = false, data } = props;
  const name = data?.name ?? i18n.t("Footer.name");
  const address = data?.address ?? i18n.t("Footer.address10");
  const email = data?.email ?? "info@amanaki.vn";
  const phone = data?.phone ?? "(+84) 82 362 4488";
  const facebook =
    data?.facebook ?? "https://www.facebook.com/amanaki.thaodien";
  const instagram =
    data?.instagram ?? "https://www.instagram.com/amanaki_thaodien";
  const { router } = useRouteContext();
  const [_showNav, $showNav] = useState([
    "ourHotels",
    "getToKnowUs",
    "explore",
    "getInTouch",
  ]);

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      $showNav([]);
    }
  }, []);

  const NAV = {
    title: "Footer.ourHotels",
    content: [
      {
        title: "Footer.thaoDien",
        link: router.toUrl("amanaki-thao-dien"),
      },
      {
        title: "Footer.boutiqueSaigon",
        link: router.toUrl("amanaki-boutique-saigon"),
      },
    ],
  };

  const NAV2 = [
    {
      title: "Footer.getToKnowUs",
      content: [
        {
          title: "Footer.whoWeAre",
          link: router.toUrl("about-us"),
        },
        {
          title: "Footer.sustainabilityImpact",
          link: router.toUrl("about-sustainability"),
        },
        {
          title: "Footer.careers",
          link: router.toUrl("about-careers"),
        },
      ],
    },
    // {
    //   title: "Footer.explore",
    //   content: [
    //     {
    //       title: "Footer.ourStories",
    //       link: router.toUrl("stories"),
    //     },
    //     {
    //       title: "Footer.ourOffice",
    //       link: "/",
    //     },
    //   ],
    // },
  ];

  const NAV3 = {
    title: "Footer.getInTouch",
    content: [
      {
        title: "Footer.contactUs",
        link: router.toUrl("contact"),
      },
    ],
  };

  return (
    <div data-scroll-section className={styles.section}>
      <div className={styles.mainInfoFooterWrapper}>
        <footer className={styles.mainInfoFooter}>
          <LogoVertical className={cx(styles.logo)} />

          <div className={cx(styles.address)}>
            <span className={cx(styles.name)}>{name}</span>
            <span className={cx(styles.value)}>{address}</span>
          </div>

          <div className={cx(styles.phone)}>
            <Icons
              name="phone-outline"
              color="#af915e"
              className={cx(styles.icon)}
              alt="phone"
            />
            <div className={cx(styles.value)}>
              <Link href={`tel:${phone}`} ariaLabel={"phone"}>
                {i18n.t("Footer.tel")}: {phone}
              </Link>
            </div>
          </div>

          <div className={cx(styles.phone)}>
            <Icons
              name="mail-outline"
              color="#af915e"
              className={cx(styles.icon)}
              alt="mail"
            />
            <div className={cx(styles.value)}>
              <Link href={`mailto:${email}`} ariaLabel={"mail"}>
                {email}
              </Link>
            </div>
          </div>

          <div className={cx(styles.socialGroup)}>
            {!hideSocialIcons && (
              <>
                <Link
                  href={instagram}
                  target="_blank"
                  rel="noopener"
                  ariaLabel="amanaki instagram"
                >
                  <Instagram />
                </Link>
                <Link
                  href={facebook}
                  target="_blank"
                  rel="noopener"
                  ariaLabel="amanaki facebook"
                >
                  <Facebook />
                </Link>
              </>
            )}
          </div>

          <div className={cx(styles.expandInfo)}>
            <Expand
              showDesktop
              label={
                <div className={cx(styles.label)}>
                  <div className={cx(styles.text)}> {i18n.t(NAV.title)}</div>
                  <div className={cx(styles.icon)}>
                    <Down />
                  </div>
                </div>
              }
              content={
                <div className={cx(styles.content)}>
                  {map(NAV.content, (content, idx) => (
                    <Link href={content.link} key={idx}>
                      <div className={cx(styles.value)}>
                        {i18n.t(content.title)}
                      </div>
                    </Link>
                  ))}
                </div>
              }
            />
          </div>

          {map(NAV2, (nav, index) => (
            <div
              className={cx(styles.expandInfo)}
              key={index}
              onClick={() => {
                if (index === 0) {
                  $showNav(["getToKnowUs"]);
                } else {
                  $showNav(["explore"]);
                }
              }}
            >
              <Expand
                showDesktop
                label={
                  <div className={cx(styles.label)}>
                    <div className={cx(styles.text)}> {i18n.t(nav.title)}</div>
                    <div className={cx(styles.icon)}>
                      <Down />
                    </div>
                  </div>
                }
                content={
                  <div className={cx(styles.content)}>
                    {map(nav.content, (content, idx) => (
                      <Link href={content.link} key={idx}>
                        <div className={cx(styles.value)}>
                          {i18n.t(content.title)}
                        </div>
                      </Link>
                    ))}
                  </div>
                }
              />
            </div>
          ))}

          <div className={cx(styles.expandInfo)}>
            <Expand
              showDesktop
              label={
                <div className={cx(styles.label)}>
                  <div className={cx(styles.text)}> {i18n.t(NAV3.title)}</div>
                  <div className={cx(styles.icon)}>
                    <Down />
                  </div>
                </div>
              }
              content={
                <div className={cx(styles.content)}>
                  {map(NAV3.content, (content, idx) => (
                    <Link href={content.link} key={idx}>
                      <div className={cx(styles.value)}>
                        {i18n.t(content.title)}
                      </div>
                    </Link>
                  ))}
                </div>
              }
            />
          </div>
          <div className={cx("absolute", styles.backToTop)}>
            <div
              className="flex items-center rotate-90"
              onClick={() => {
                scroll.scrollTo(0, { duration: 500 });
              }}
            >
              <div className="mr-6 -ml-2 -rotate-90">
                <Icons name="collapse-up" size={40} />
              </div>
              <div className="uppercase ">{i18n.t("Button.backToTop")}</div>
            </div>
          </div>
        </footer>
      </div>
      <div className={cx(styles.copyrightSectionWrapper)}>
        <div className={cx(styles.copyrightSection)}>
          <div>{i18n.t("Footer.copyright")}</div>
          <span>
            <Link
              target={"_blank"}
              href={router.toUrl("privacy", {
                legalNoticeType: "PrivacyPolicy",
              })}
              ariaLabel={"privacy policy"}
            >
              {i18n.t("Footer.privacyPolicy")}
            </Link>
          </span>
          {/* <div
               className="flex flex-col md:flex-row"
               style={{ columnGap: "24px", rowGap: "16px" }}
             >
               <span>{i18n.t("Footer.privacyPolicy")}</span>
               <span>{i18n.t("Footer.adCookiePolicy")}</span>
               <span>{i18n.t("Footer.legalNotices")}</span>
             </div> */}
        </div>
        <div className={cx("", styles.backToTopMobile)}>
          <div
            className="flex items-center rotate-90"
            style={{ width: "148px" }}
            onClick={() => {
              scroll.scrollTo(0, { duration: 500 });
            }}
          >
            <div className="mr-2 -rotate-90">
              <Icons name="collapse-up" size={40} />
            </div>
            <div className="uppercase ">{i18n.t("Button.backToTop")}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
