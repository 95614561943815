import React from "react";
import DatePicker from "react-datepicker";
import { useFormikContext } from "formik";
import CustomInputStyle from "./CustomInputStyle";

import { Field } from "formik";
const View = ({
  name,
  validate,
  fast,
  placeholder = "",
  customInputProps = {},
  border = false,
  ariaLabel = "",
  format = "default",
}: any) => {
  const ref: any = React.useRef({});
  const form = useFormikContext();

  const [date, $date] = React.useState(null);
  const handler = {
    onChange: (e: any) => {
      form.setFieldValue(name, e);
      $date(e);
    },
  };
  return (
    <Field name={name} validate={validate} fast={fast}>
      {({ field, form }: any) => {
        ref.current.form = form;
        return (
          <DatePicker
            popperClassName="pb-popper"
            selected={date}
            placeholderText={placeholder}
            customInput={
              <CustomInputStyle
                {...customInputProps}
                ariaLabel={ariaLabel || name}
                field={field}
                date={date}
                format={format}
                border={border}
              />
            }
            onChange={handler.onChange}
          />
        );
      }}
    </Field>
  );
};

export default View;
