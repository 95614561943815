import React from 'react';
import _ from 'lodash';
import { FormikProvider } from 'formik';

const FormProvider = ({
  children,
  form,
}: {
  children: JSX.Element;
  form: any;
}) => {
  return (
    <FormikProvider value={form}>
      {_.isFunction(children) ? children(form) : children}
    </FormikProvider>
  );
};

export default FormProvider;
