const View = ({ ...props }: any) => {
  return (
    <svg width="69" height="72" viewBox="0 0 69 72" fill="none" {...props}>
      <g clipPath="url(#clip0_5292_23032)">
        <path
          d="M57.2674 20.4784L51.5625 16.6543L50.9043 20.9172L57.2674 20.4784Z"
          fill="#F9C254"
        />
        <path
          d="M29.4637 33.6445L20.8438 50.6963L31.9086 42.5152C31.7205 41.4181 29.9025 35.0551 29.4637 33.6445Z"
          fill="#F1D380"
        />
        <path
          d="M42.3466 21.7321L40.1211 23.7696L48.553 34.2389L51.3113 16.6855L42.3466 21.7321Z"
          fill="#F1D380"
        />
        <path
          d="M35.6078 18.5352L29.6836 33.2047L48.3654 34.3958L35.6078 18.5352Z"
          fill="#E7B144"
        />
        <path
          d="M22.9132 21.1057L10.3438 20.3848L29.1822 32.9542L22.9132 21.1057Z"
          fill="#B68D5A"
        />
        <path
          d="M15.7344 7L29.4949 33.0479L35.4192 18.347L15.7344 7Z"
          fill="#DDA43A"
        />
        <path
          d="M48.1774 34.6149L29.6523 33.4551L32.2853 42.2944L48.1774 34.6149Z"
          fill="#F9C254"
        />
        <path
          d="M6.11232 62.3879H2.69569L2.19417 63.4537C2.13148 63.6104 2.10013 63.7044 2.10013 63.7985C2.10013 63.9552 2.22551 64.0806 2.47627 64.1433V64.3314H0V64.1433C0.156726 64.1119 0.250762 64.0492 0.344798 63.9866C0.407488 63.9239 0.501524 63.7671 0.595559 63.5477L3.35394 57.8115C3.44798 57.6235 3.47932 57.4981 3.47932 57.3727C3.47932 57.216 3.38529 57.0906 3.16587 56.9966V56.8398H5.79887V57.0279C5.57945 57.0906 5.48542 57.1846 5.48542 57.3414C5.48542 57.4354 5.51676 57.5608 5.57945 57.7175L8.43187 63.4537C8.55725 63.7045 8.65128 63.8612 8.74532 63.9552C8.83936 64.0492 8.96474 64.1119 9.12146 64.1433V64.3314H6.36308V64.1433C6.61384 64.1119 6.73923 63.9866 6.73923 63.7985C6.73923 63.7358 6.70788 63.6104 6.64519 63.4537L6.11232 62.3879ZM5.64214 61.3849L4.41968 58.7206L3.19721 61.3849H5.64214Z"
          fill="#C49A6C"
        />
        <path
          d="M15.8929 64.3L13.1345 59.1594V63.7358C13.1345 63.8612 13.1659 63.9239 13.1972 63.9866C13.2599 64.0492 13.3539 64.0806 13.4793 64.1433V64.3314H11.2852V64.1433C11.4105 64.1119 11.5046 64.0492 11.5673 63.9866C11.63 63.9239 11.63 63.8298 11.63 63.7358V57.4041C11.63 57.31 11.5986 57.216 11.5673 57.1533C11.5046 57.0906 11.4105 57.0593 11.2852 56.9966V56.8398H14.1689V57.0279C14.0122 57.0593 13.9182 57.1846 13.9182 57.3414C13.9182 57.4041 13.9495 57.4981 13.9808 57.5921L16.0183 61.4476L18.0871 57.6548C18.1811 57.4981 18.2125 57.3727 18.2125 57.2787C18.2125 57.1219 18.1184 57.0593 17.9303 57.0279V56.8398H20.7514V57.0279C20.626 57.0593 20.532 57.122 20.4693 57.1846C20.4066 57.2473 20.4066 57.3414 20.4066 57.4354V63.7358C20.4066 63.8612 20.438 63.9239 20.4693 63.9866C20.532 64.0492 20.626 64.0806 20.7514 64.1433V64.3314H18.4319V64.1433C18.5573 64.1119 18.6513 64.0492 18.714 64.0179C18.7767 63.9552 18.808 63.8612 18.808 63.7671V59.1907L15.8929 64.3Z"
          fill="#C49A6C"
        />
        <path
          d="M29.1513 62.3879H25.766L25.2645 63.4223C25.2018 63.5791 25.1704 63.6731 25.1704 63.7671C25.1704 63.9239 25.2958 64.0492 25.5466 64.1119V64.3H23.0703V64.1119C23.227 64.0806 23.3211 64.0179 23.4151 63.9552C23.4778 63.8925 23.5718 63.7358 23.6659 63.5164L26.4243 57.7802C26.5183 57.5921 26.5496 57.4668 26.5496 57.3414C26.5496 57.1846 26.4556 57.0593 26.2362 56.9652V56.8398H28.8378V57.0279C28.6184 57.0906 28.5244 57.1846 28.5244 57.3414C28.5244 57.4354 28.5557 57.5608 28.6184 57.7175L31.4708 63.4537C31.5962 63.7045 31.6903 63.8612 31.7843 63.9552C31.8783 64.0492 32.0037 64.1119 32.1604 64.1433V64.3314H29.4021V64.1433C29.6528 64.1119 29.7782 63.9866 29.7782 63.7985C29.7782 63.7358 29.7468 63.6104 29.6842 63.4537L29.1513 62.3879ZM28.6811 61.3849L27.4586 58.7206L26.2362 61.3849H28.6811Z"
          fill="#C49A6C"
        />
        <path
          d="M36.2029 59.1907V63.7358C36.2029 63.8612 36.2342 63.9239 36.2656 63.9866C36.3283 64.0492 36.4223 64.0806 36.5477 64.1433V64.3314H34.3535V64.1433C34.4789 64.1119 34.5729 64.0492 34.6356 63.9866C34.6983 63.9239 34.6983 63.8298 34.6983 63.7358V57.4041C34.6983 57.31 34.667 57.216 34.6356 57.1533C34.5729 57.0906 34.4789 57.0593 34.3535 56.9966V56.8398H36.8925V57.0279C36.7358 57.0593 36.6731 57.1533 36.6731 57.2787C36.6731 57.3727 36.7358 57.4667 36.8298 57.5608L40.936 61.8237V57.4041C40.936 57.31 40.9047 57.216 40.8733 57.1533C40.8106 57.0906 40.7166 57.0593 40.5912 56.9966V56.8398H42.7854V57.0279C42.66 57.0593 42.566 57.122 42.5033 57.1846C42.4406 57.2473 42.4406 57.3414 42.4406 57.4354V63.7358C42.4406 63.8612 42.4719 63.9239 42.5033 63.9866C42.566 64.0492 42.66 64.0806 42.7854 64.1433V64.3314H40.4345V64.1433C40.5912 64.1119 40.6539 64.0179 40.6539 63.9239C40.6539 63.8612 40.5599 63.7358 40.3718 63.5477L36.2029 59.1907Z"
          fill="#C49A6C"
        />
        <path
          d="M51.2489 62.3879H47.8637L47.3621 63.4223C47.2994 63.5791 47.2681 63.6731 47.2681 63.7671C47.2681 63.9239 47.3935 64.0492 47.6442 64.1119V64.3H45.168V64.1119C45.3247 64.0806 45.4187 64.0179 45.5128 63.9552C45.5755 63.8925 45.6695 63.7358 45.7635 63.5164L48.5219 57.7802C48.6159 57.5921 48.6473 57.4668 48.6473 57.3414C48.6473 57.1846 48.5533 57.0593 48.3338 56.9652V56.8398H50.9668V57.0279C50.7474 57.0906 50.6534 57.1846 50.6534 57.3414C50.6534 57.4354 50.6847 57.5608 50.7474 57.7175L53.5998 63.4537C53.7252 63.7045 53.8193 63.8612 53.9133 63.9552C54.0073 64.0492 54.1327 64.1119 54.2894 64.1433V64.3314H51.531V64.1433C51.7818 64.1119 51.9072 63.9866 51.9072 63.7985C51.9072 63.7358 51.8758 63.6104 51.8132 63.4537L51.2489 62.3879ZM50.7788 61.3849L49.5563 58.7206L48.3338 61.3849H50.7788Z"
          fill="#C49A6C"
        />
        <path
          d="M59.5562 60.8207L58.4278 61.7297V63.7358C58.4278 63.8612 58.4591 63.9239 58.5218 63.9866C58.5845 64.0492 58.6785 64.0806 58.8039 64.1119V64.3H56.4844V64.1119C56.6098 64.0806 56.7038 64.0179 56.7665 63.9552C56.8292 63.8925 56.8292 63.7985 56.8292 63.7044V57.3727C56.8292 57.2787 56.7978 57.1846 56.7665 57.1219C56.7038 57.0593 56.6098 57.0279 56.4844 56.9652V56.8398H58.8039V57.0279C58.6785 57.0593 58.5845 57.122 58.5218 57.1533C58.4591 57.216 58.4591 57.31 58.4591 57.4041V60.0057L61.531 57.4981C61.6877 57.3727 61.7504 57.2787 61.7504 57.216C61.7504 57.1219 61.6563 57.0279 61.4369 56.9966V56.8398H64.4774V57.0279C64.352 57.0593 64.258 57.0906 64.1953 57.1219C64.1326 57.1533 63.9759 57.2787 63.7251 57.4981L63.3176 57.8429L60.7787 59.8803L63.6624 63.3283C63.9132 63.6418 64.1326 63.8612 64.2266 63.9552C64.352 64.0492 64.5088 64.1119 64.6655 64.1433V64.3314H61.7504V64.1433C61.9384 64.1119 62.0325 64.0492 62.0325 63.9239C62.0325 63.8612 61.9384 63.7358 61.7817 63.5164L59.5562 60.8207Z"
          fill="#C49A6C"
        />
        <path
          d="M67.3604 63.7358V57.4041C67.3604 57.31 67.3291 57.216 67.2977 57.1533C67.235 57.0906 67.141 57.0593 67.0156 56.9966V56.8398H69.3352V57.0279C69.2098 57.0593 69.1158 57.122 69.0531 57.1533C68.9904 57.216 68.9904 57.31 68.9904 57.4041V63.7358C68.9904 63.8612 69.0217 63.9239 69.0844 63.9866C69.1471 64.0492 69.2411 64.0806 69.3665 64.1119V64.3H67.047V64.1119C67.1723 64.0806 67.2664 64.0179 67.3291 63.9552C67.3291 63.9239 67.3604 63.8298 67.3604 63.7358Z"
          fill="#C49A6C"
        />
      </g>
    </svg>
  );
};
export default View;
