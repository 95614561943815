import React from "react";
import styles from "./Dropdown.module.scss";
import cx from "classnames";
import useDocumentClick from "@hooks/useDocumentClick";
import _ from "lodash";

const View = React.forwardRef(
  (
    {
      className,
      itemProps = {},
      optionsProps = {},
      optionProps = {},
      value,
      placeholder = "",
      allowClickOption = false,
      typeClickOutside = "document",
      label,
      onChange,
      onToggle,
      onBlur,
      options,
      children,
      border = false,
      arrow = true,
      amin = false,
    }: any,
    _ref: any
  ) => {
    const currentRef: any = React.useRef({});
    const dropdownArea: any = React.useRef({});

    const handler = {
      toggle: (event: any) => {
        if (currentRef && currentRef.current) {
          const isArea =
            dropdownArea &&
            !_.isEmpty(dropdownArea.current) &&
            dropdownArea.current.contains(event.target);
          if (currentRef.current.classList.contains(styles.active)) {
            if (!allowClickOption || (allowClickOption && !isArea)) {
              currentRef.current.classList.remove(styles.active);
              _.isFunction(onToggle) ? onToggle(false) : null;
            }
          } else {
            currentRef.current.classList.add(styles.active);
            _.isFunction(onToggle) ? onToggle(true) : null;
          }
        }
      },

      handleClickOutside: (event: any) => {
        const isArea =
          dropdownArea &&
          !_.isEmpty(dropdownArea.current) &&
          dropdownArea.current.contains(event.target);
        if (
          currentRef &&
          currentRef.current &&
          !currentRef.current.contains(event.target)
        ) {
          if (!allowClickOption || (allowClickOption && !isArea)) {
            _.isFunction(onBlur) ? onBlur() : null;
            if (currentRef.current.classList.contains(styles.active))
              currentRef.current.classList.remove(styles.active);
          }
        }
      },

      onBlur: () => {
        if (
          currentRef &&
          currentRef.current.classList.contains(styles.active)
        ) {
          _.isFunction(onBlur) ? onBlur() : null;
          currentRef.current.classList.remove(styles.active);
        }
      },
    };
    useDocumentClick(handler.handleClickOutside, typeClickOutside);
    const labelCurrent = React.useMemo(
      () =>
        _.get(
          _.find(options, (opt) => opt.value === value),
          "label",
          ""
        ),
      [value]
    );
    return (
      <div
        ref={currentRef}
        className={cx(styles.dropdown, className, {
          [styles.border]: border,
          [styles.aminUnderline]: amin,
        })}
        onClick={handler.toggle}
        onBlur={handler.onBlur}
      >
        <div
          {...itemProps}
          className={cx(styles.currentItem, itemProps.className, {
            [styles.noArrow]: !arrow,
          })}
        >
          {!!(placeholder && !labelCurrent) && (
            <label className={styles.labelPlaceholder}>{placeholder}</label>
          )}
          {!!labelCurrent && (
            <label className={styles.labelValue}>{labelCurrent}</label>
          )}
          {!!label && label}
        </div>
        <div
          {...optionsProps}
          className={cx(styles.options, optionsProps.className)}
        >
          {_.map(options, (option: any, index: number) => {
            return (
              <div
                {...optionProps}
                key={`option_${index}`}
                className={cx(styles.option, optionProps.className)}
                onClick={() => {
                  onChange(option);
                }}
              >
                <label>{option.label}</label>
              </div>
            );
          })}
          {!!children && <div ref={dropdownArea}>{children}</div>}
        </div>
        <span className={styles.bar} />
      </div>
    );
  }
);

View.displayName = "Dropdown";

export default View;
