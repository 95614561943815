const vi = {
  Language: {
    vi: "Tiếng Việt",
    en: "English",
  },
  NAVS: {
    home: "Trang chủ",
    ourHotels: "Các khách sạn",
    contactUs: "Liên hệ Amanaki",
    aboutUs: "Giới thiệu",
    sustainability: "Phát triển bền vững",
    careers: "Nghề nghiệp",
    discoverOurHotels: "Khám phá khách sạn",
    amanakiSaigonBoutique: "Amanaki Saigon Boutique",
    amanakiThaoDien: "Amanaki Thảo Điền",
    amanakiHotel: "Amanaki Hotels",
    theHotel: "Khách sạn",
    brandStory: "Câu chuyện Amanaki Thảo Điền",
    artAmanaki: "Nghệ thuật và Amanaki",
    rooms: "Phòng nghỉ",
    wellness: "Sức khỏe",
    dining: "Nhà hàng",
    events: "Sự kiện",
    offers: "Ưu đãi",
    discoverSaigon: "Khám phá Sài Gòn",
  },
  Form: {
    required: "Trường thông tin bắt buộc",
    emailInvalid: "Email không hợp lệ.",
    mustBeAccept: "Phải được chấp nhận",
    placeholderFirstName: "Tên",
    placeholderLastName: "Họ",
    placeholderEmail: "Địa chỉ email",
    placeholderPhone: "Số điện thoại",
    placeholderEnquiry: "Chọn loại phản hồi",
    placeholderWhichHotel: "Bạn muốn liên hệ với khách sạn nào?",
    placeholderMessage: "Lời nhắn của bạn",
    placeholderDepartureDate: "Ngày đi",
    placeholderArrivalDate: "Ngày đến",
    placeholderRoomType: "Chọn loại phòng",
    placeholderAll: "Tất cả",
    labelPhone: "Điện thoại",
  },
  Options: {
    Enquiry: {
      hotelReservation: "Hotel Reservation",
      wellnessReservation: "Wellness Reservation",
      meetingsEvents: "Meetings & Events",
      pressMedia: "Press & media",
      generalEnquiryFeedback: "General Enquiry & Feedback",
    },
  },
  Notification: {
    submitSuccess: "Thông tin của bạn đã được gửi.",
  },
  Footer: {
    name: "Amanaki Hotels",
    address: "Thảo Điền, Thủ Đức, Hồ Chí Minh",
    address10: "10 Nguyễn Đăng Giai, Thảo Điền, Hồ Chí Minh, Việt Nam",
    address65: "65 Hồ Tùng Mậu, Phường Bến Nghé, Quận 1, Hồ Chí Minh",
    email: "info@amanaki.vn",
    email10: "info@amanaki.vn",
    email65: "info@amanakihotels.com",
    phone: "(+84) 82 362 4488",
    phone10: "(+84) 82 362 4488",
    phone65: "(+84) 82 363 4488",
    tel: "Tel",
    ourHotels: "Khách sạn của chúng tôi",
    thaoDien: "Amanaki Thảo Điền",
    boutiqueSaigon: "Amanaki Sài Gòn Boutique",
    amanakiHotels: "Amanaki Hotels",
    getToKnowUs: "Về Amanaki",
    whoWeAre: "Giới thiệu",
    sustainabilityImpact: "Phát triển bền vững",
    ourStories: "Our stories",
    explore: "Tìm hiểu thêm",
    careers: "Nghề nghiệp",
    ourOffice: "Our office",
    getInTouch: "Liên hệ",
    contactUs: "Liên hệ Amanaki",
    copyright: "© 2023 Amanaki Hotels. All Rights Reserved.",
    privacyPolicy: "Chính sách bảo mật",
    adCookiePolicy: "Ad & Cookie Policy",
    legalNotices: "Legal Notices",
  },
  Header: {
    selectAHotel: "Chọn khách sạn",
    aboutAmanaki: "Về Amanaki",
    ourStories: "Blog",
    contactUs: "Liên hệ",
    ourBlog: "Tin tức",
    aboutUs: "Giới thiệu",
    sustainability: "Phát triển bền vững",
    careers: "Nghề nghiệp",
  },
  Button: {
    bookNow: "Đặt phòng",
    discoverMore: "Tìm hiểu thêm",
    moreDetail: "Chi tiết",
    moreDetails: "Chi tiết",
    allOffer: "Xem tất cả ưu đãi",
    viewThisRoom: "Thăm phòng",
    applyNow: "Nộp đơn ứng tuyển",
    viewMap: "Xem bản đồ",
    contactUs: "Liên hệ",
    moreAboutUs: "Tìm hiểu thêm",
    enterOurSpaces: "Xem tất cả phòng",
    bookThisRoom: "Đặt phòng",
    exploreArtsAndAmanaki: "Tìm hiểu thêm Amanaki & Nghệ thuật",
    viewAllStories: "Xem tất cả câu chuyện",
    viewAll: "Xem tất cả",
    reserve: "Đặt trước",
    readMore: "Xem thêm",
    exploreMore: "Khám phá thêm",
    viewCareers: "Xem thông tin",
    makeAnEnquire: "Liên hệ đặt hẹn",
    getToKnowOurExecutiveChef: "Get to know our executive chef",
    visitOurRestaurant: "Visit our restaurant",
    seeOurFacilities: "See our facilities",
    submit: "Gửi",
    checkTheMap: "Xem bản đồ",
    goToMap: "Xem bản đồ",
    requestForProposal: "Yêu cầu đề xuất",
    bookDirectWithUs: "Đặt phòng trực tiếp với chúng tôi",
    back: "Quay về",
    backToTop: "Về Đầu Trang",
  },
  FAQs: {
    FAQs: "FAQs",
    slogan: "Chúng tôi ở đây để đáp ứng mọi nhu cầu của bạn!",
  },
  Stories: {
    title: "Câu chuyện cảm hứng",
    topic: {
      wellness: "Wellness",
      liveLikeLocal: "Live like a local",
      whatOn: "What's on",
    },
    lastestStories: "Câu Chuyện Mới Nhất",
    interested: "Các chuyên mục",
    exploreAllStories: "Các bài viết khác",
    tags: "Thẻ",
    share: "Chia sẻ câu chuyện",
  },
  About: {
    aboutAmanaki: "Về Amanaki",
    aboutUs: "Giới thiệu",
    sustainableDevelopment: "Phát triển bền vững",
    careers: "Nghề nghiệp",
    sustainable: {
      blogs: {
        heading: "Các tin tức khác",
      },
    },
  },
  Wellness: {
    wellness: "Sức khỏe",
    facilitiesService: "Cơ sở vật chất & dịch vụ",
    gallery: "Hình ảnh",
    hours: "Thời gian",
    reservations: "Liên hệ",
    location: "Vị trí",
    wellbeingCentre: {
      wellbeingCentre: "Wellbeing Centre",
    },
    facilities: {
      facilities: "Tiện ích",
    },
  },
  Dinning: {
    dinning: "Nhà hàng",
    restaurantInfo: {
      title: "Thông tin nhà hàng",
      cuisine: {
        label: "Ẩm thực",
        fusionInspired: "Việt Nam",
        viewMenu: "Xem thực đơn",
      },
      reservations: {
        label: "Đặt bàn",
        phone: "082 841 1010",
        email: "info@amanaki.vn",
        address: "8 Nguyễn Đăng Giai, Thảo Điền, Quận 2",
      },
      openingHours: {
        label: "Giờ mở cửa",
        allDay: "All Day",
        dinning: "06:00 - 22:00",
        lastOrder: "(đặt món trước 21:45)",
      },
      executiveChef: {
        label: "Bếp trưởng",
        name: "Đồng Hoàng Nam",
      },
      moreInfo: {
        label: "Thông tin thêm",
        amanakiFacebook: "Amanaki Facebook",
      },
    },
  },
  MeetingAndEvents: {
    meetingAndEvents: "Hội nghị & Sự kiện",
    meetingAndCelebrations: "Sự kiện & Lễ hội",
    labelBanner: "Không gian sáng tạo cho các sự kiện nghệ thuật",
    overview: {
      title: "Sự kiện nghệ thuật & triển lãm tại Amanaki Thao Dien",
      desc: "Theo đúng nghĩa đen lẫn nghĩa bóng, 11:11 Espace tọa lạc ngay giữa lòng hy vọng - Amanaki Thảo Điền - nơi chốn giao thoa nơi các chuyện kể được tạo nên và san sẻ cùng nhau. Không gian sự kiện kiêm triển lãm 250 mét vuông là nơi các nghệ sĩ trong nước và quốc tế đương thời thể hiện tài năng của mình, với sự điều phối và tuyển chọn bởi 11:11 Espace. Nếu bạn còn đang ấp ủ một sự kiện nghệ thuật và giao lưu “đúng nghĩa”, đừng ngần ngại liên hệ với chúng tôi!",
      phone: "+84 82 362 4488",
      email: "info@amanaki.vn",
    },
    requestForProposal: {
      form: {
        fullName: "Tên Quý Khách",
        emailAddress: "Địa Chỉ Email",
        phoneNumber: "Điện Thoại",
        numberOfAttendees: "Số Khách Dự Kiến Tham Gia",
        desiredSetup: "Yêu Cầu Set Up",
        desiredSetupOptions: JSON.stringify([
          { label: "Tiệc", value: "Banquet" },
          { label: "Tiệc Cocktail", value: "CocktailReception" },
          { label: "Kiểu Nhà hát", value: "Theatre" },
          { label: "Hình Chữ U", value: "Ushape" },
          { label: "Khác", value: "Other" },
        ]),
        eventDate: "Ngày Dự Kiến",
        note: "Thông Tin Sự Kiện, Bình Luận, Câu hỏi Hoặc Những Yêu Cầu Đặc Biệt Khác",
        condition: {
          readAndAccept: "Tôi đã đọc và đồng ý với",
          privacyPolicy: "Chính sách bảo mật",
          keepUpToDateInfo:
            "Tôi muốn đăng ký nhận các thông tin cập nhật mới nhất",
        },
      },
    },
  },
  BrandStory: {
    banner: {
      title: "Câu chuyện Amanaki Thảo Điền",
    },
  },
  Arts: {
    banner: {
      title: "Nghệ thuật & Amanaki",
    },
    exhibitions: {
      tabs: JSON.stringify([
        { label: "TRIỂN LÃM SẮP TỚI", value: "upcomingExhibition" },
        { label: "TRIỂN LÃM ĐANG DIỄN RA", value: "currentExhibition" },
        { label: "TRIỂN LÃM TRƯỚC ĐÓ", value: "previousExhibition" },
      ]),
      status: {
        ongoing: "ĐANG DIỄN RA",
        exhibition: "TRIỂN LÃM",
      },
    },
  },
  LegalNotice: {
    banner: {
      title: "Thông Tin Pháp Lý",
    },
    menuOptions: JSON.stringify([
      { label: "Chính Sách Bảo Mật", value: "PrivacyPolicy" },
      { label: "Cookies", value: "Cookies" },
      { label: "Đảm bảo Giá tốt nhất", value: "BestRateGuaranteed" },
    ]),
    cookies: {
      heading: "Cookie",
      data: JSON.stringify([
        {
          title: `1. Cookie là gì?`,
          content: `Cookie là các tệp văn bản nhỏ hoặc các mẩu thông tin được lưu trữ trên máy tính hoặc thiết bị di động của bạn <i>(chẳng hạn như điện thoại thông minh hoặc máy tính bảng)</i> khi bạn truy cập các trang web của chúng tôi. Cookie thường sẽ chứa tên của trang web mà từ đó cookie đến, “thời gian tồn tại” của cookie <i>(tức là thời gian cookie sẽ tồn tại trên thiết bị của bạn)</i>, và một giá trị, thường là một số duy nhất được tạo ngẫu nhiên.`,
        },
        {
          title: `2. Chúng tôi sử dụng cookie để làm gì?`,
          content: `Chúng tôi sử dụng cookie để làm cho các trang web của chúng tôi dễ sử dụng hơn và điều chỉnh tốt hơn các trang web và sản phẩm của chúng tôi cho phù hợp với sở thích và nhu cầu của bạn. Cookie có thể làm điều này vì các trang web có thể đọc và ghi các tệp này, cho phép chúng nhận ra bạn và ghi nhớ thông tin quan trọng sẽ giúp bạn sử dụng trang web thuận tiện hơn <i>(ví dụ: bằng cách ghi nhớ cài đặt tùy chọn)</i>. Cookie cũng có thể được sử dụng để giúp tăng tốc các hoạt động và trải nghiệm trong tương lai của bạn trên các trang web của chúng tôi. Chúng tôi cũng sử dụng cookie để biên soạn các số liệu thống kê tổng hợp, ẩn danh cho phép chúng tôi hiểu cách người dùng sử dụng các trang web của chúng tôi và giúp chúng tôi cải thiện cấu trúc và nội dung của các trang web của mình. Chúng tôi không thể nhận dạng cá nhân bạn từ thông tin này.`,
        },
        {
          title: `3. Chúng tôi sử dụng những loại cookie nào?`,
          content: `Hai loại cookie có thể được sử dụng trên các trang web, được gọi là “Session cookies” và “Persistent cookies”. Session cookies là cookie tạm thời vẫn còn trên thiết bị của bạn cho đến khi bạn rời khỏi Trang web. Persistent cookies vẫn còn trên thiết bị của bạn lâu hơn nữa hoặc cho đến khi bạn xóa nó theo cách thủ công <i>(thời gian cookie còn lại trên thiết bị của bạn sẽ phụ thuộc vào thời lượng hoặc “thời gian tồn tại” của cookie cụ thể)</i>.
Cookie của bên thứ ba
Chúng tôi cũng sử dụng một số nhà cung cấp có thể thay mặt chúng tôi đặt cookie trên thiết bị của bạn khi bạn truy cập các trang web của chúng tôi để cho phép họ cung cấp các dịch vụ mà họ đang cung cấp. Nếu bạn muốn biết thêm thông tin về các cookie này, cũng như thông tin về cách chọn không nhận các cookie đó, vui lòng xem chính sách bảo mật của chúng.`,
        },
        {
          title: `4. Chúng tôi sử dụng cookie nào?`,
          content: [
            {
              text: `Dưới đây, chúng tôi liệt kê các loại cookie khác nhau có thể được sử dụng trên các trang web của Amanaki. Lưu ý rằng trong phạm vi thông tin thu thập được thông qua cookie cấu thành thông tin cá nhân, các quy định trong Chính sách bảo mật sẽ được áp dụng và bổ sung cho Chính sách cookie này.`,
            },
            {
              bullet: true,
              text: `<span style="font-weight: 405;">Cookie thiết yếu:</span>
Các cookie thiết yếu là là yếu tố quan trọng và cần thiết để Trang web hoạt động, cho phép bạn di chuyển xung quanh và sử dụng các dịch vụ cũng như tính năng. Nếu không có các cookie này, Trang web sẽ không hoạt động trơn tru như bạn muốn và chúng tôi không thể cung cấp Trang web hoặc một số dịch vụ hoặc tính năng nhất định mà bạn yêu cầu.
<span style="font-weight: 405;">Phiên</span> – Cookie Phiên được sử dụng để duy trì trạng thái của ứng dụng. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.
<span style="font-weight: 405;">Cân bằng tải</span> – Cookie Cân bằng tải được sử dụng để phân phối tài sản trên toàn cầu và làm nhẹ tải máy chủ. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.
<span style="font-weight: 405;">ID người dùng</span> – Cookie ID người dùng được sử dụng để đảm bảo rằng người dùng chỉ nhìn thấy thông tin của riêng họ. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.
<span style="font-weight: 405;">Bảo mật</span> – Cookie bảo mật được sử dụng để kiểm soát và kiểm tra bảo mật. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.`,
            },
            {
              bullet: true,
              text: `<span style="font-weight: 405;">Cookie ưu tiên</span>
Cookie ưu tiên thu thập thông tin về các lựa chọn và sở thích của bạn và cho phép chúng tôi ghi nhớ ngôn ngữ hoặc các cài đặt cục bộ khác và tùy chỉnh Trang web phù hợp.
<span style="font-weight: 405;">Ngôn ngữ</span> – Cookie ngôn ngữ được sử dụng để lưu trữ ngôn ngữ mà người dùng đã chọn và để hiển thị các tùy chọn chính xác. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.
<span style="font-weight: 405;">Địa điểm</span> – Địa chỉ gần đúng của người dùng <i>(thành phố, tiểu bang, quốc gia, mã bưu chính)</i> được xác định bởi địa chỉ IP được giữ lại để tự động chọn quốc gia phù hợp và hiển thị các nhà bán lẻ và ngày trình diễn trong khu vực. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.
<span style="font-weight: 405;">Di động</span> – Nếu người dùng đang xem Trang web trên thiết bị di động, cookie được đặt để cho biết rằng trang web chính đã được chọn <i>(nghĩa là thiết bị có bật Flash)</i>, hoặc trang web không dành cho thiết bị di động Flash. quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.
<span style="font-weight: 405;">Trang tham chiếu</span> – Trang tham chiếu được ghi lại để hiểu rõ hơn về sở thích của người dùng. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.
<span style="font-weight: 405;">Lần truy cập và hoạt động gần đây</span> – Ngày truy cập và hoạt động gần đây nhất và các thông tin khác được ghi lại để có thể cung cấp cho người dùng bản cập nhật về điều gì mà Thay đổi trên trang web kể từ lần truy cập trước của bạn, Khăn và để hiểu rõ hơn về sở thích của người dùng. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.
<span style="font-weight: 405;">Video được xem gần đây</span> – Ngày và tiêu đề của video được xem gần đây được ghi lại để có thể hiểu rõ hơn về sở thích của người dùng. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.
<span style="font-weight: 405;">Flash Cookie</span> – Cookie flash được sử dụng để cho phép phát nội dung âm thanh và video. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.
<span style="font-weight: 405;">Lịch sử trang</span> – Cookie lịch sử trang được sử dụng để theo dõi chuỗi trang mà người dùng đã truy cập. Nếu người dùng nhận được thông báo lỗi trong khi truy cập Trang web, thông tin cookie sẽ được lưu vào tệp nhật ký để báo cáo và giải quyết lỗi. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông.`,
            },
            {
              bullet: true,
              text: `<span style="font-weight: 405;">Cookie theo dõi trình cắm thêm xã hội</span>
Các cookie này được sử dụng để theo dõi các thành viên [và không phải thành viên] của các mạng truyền thông xã hội để phân tích nghiên cứu thị trường và phát triển sản phẩm.
<span style="font-weight: 405;">Facebook</span> – Cookies được sử dụng để theo dõi các thành viên Facebook [và không phải thành viên] để phân tích nghiên cứu thị trường và phát triển sản phẩm. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.`,
            },
            {
              bullet: true,
              text: `<span style="font-weight: 405;">Cookie phân tích</span>
Cookie phân tích thu thập thông tin về việc bạn sử dụng Trang web và cho phép chúng tôi cải thiện cách thức hoạt động của nó. Ví dụ: cookie phân tích cho chúng tôi biết những trang nào được truy cập thường xuyên nhất trên Trang web, giúp chúng tôi ghi lại mọi khó khăn bạn gặp phải với Trang web và cho chúng tôi biết quảng cáo của chúng tôi có hiệu quả hay không. Điều này cho phép chúng tôi xem các mô hình sử dụng tổng thể trên Trang web, thay vì sử dụng của một người.
<span style="font-weight: 405;">Google analytics</span> – Cookie Google Analytics thu thập dữ liệu thống kê tổng hợp để cải thiện việc trình bày và điều hướng Trang web. Google bổ sung dữ liệu tổng hợp bằng thông tin nhân khẩu học và sở thích để chúng tôi có thể hiểu rõ hơn về khách truy cập của mình. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.
<span style="font-weight: 405;">Adobe Omniture</span> – Cookie phân tích Adobe Omnatio thu thập dữ liệu thống kê tổng hợp để cải thiện việc trình bày và điều hướng Trang web. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.`,
            },
            {
              bullet: true,
              text: `<span style="font-weight: 405;">Tiếp thị cookie</span>
Cookies quảng cáo được sử dụng cho mục đích tiếp thị.
<span style="font-weight: 405;">Quảng cáo</span> – Cung cấp quảng cáo theo hành vi / mục tiêu. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.
<span style="font-weight: 405;">Phân tích thị trường</span> – Tiến hành phân tích thị trường. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.
<span style="font-weight: 405;">Chiến dịch / khuyến mãi</span> – Đo lường hiệu quả của chiến dịch. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.
<span style="font-weight: 405;">Phát hiện gian lận</span> – Phát hiện gian lận nhấp chuột. Chúng có thể được quản lý bằng cách chấp nhận / từ chối thông qua cài đặt trình duyệt.`,
            },
          ],
        },
        {
          title: `5. Làm cách nào để quản lý cookie?`,
          content: `Mặc dù hầu hết các trình duyệt internet ban đầu được thiết lập để tự động chấp nhận cookie, nhưng hầu hết các trình duyệt internet cho phép bạn thay đổi cài đặt để chặn cookie hoặc để thông báo cho bạn khi cookie được gửi đến thiết bị của bạn.
Ngoài các tùy chọn được cung cấp ở trên, bạn có thể từ chối, chấp nhận hoặc xóa cookie khỏi Trang web bất cứ lúc nào bằng cách kích hoạt hoặc truy cập cài đặt trên trình duyệt của bạn. Thông tin về quy trình cần tuân thủ để bật, tắt hoặc xóa cookie có thể được tìm thấy trên trang web của nhà cung cấp trình duyệt Internet của bạn thông qua màn hình trợ giúp của bạn.
Xin lưu ý rằng nếu cookie bị vô hiệu hóa hoặc bị xóa, không phải tất cả các tính năng của Trang web có thể hoạt động như dự định, ví dụ: bạn không thể truy cập các khu vực nhất định trên Trang web của chúng tôi hoặc bạn không thể nhận được thông tin cá nhân khi bạn truy cập Trang web.
Nếu bạn sử dụng các thiết bị khác nhau để xem và truy cập các trang web <i>(ví dụ: máy tính, điện thoại thông minh, máy tính bảng, v.v.)</i> bạn sẽ cần đảm bảo rằng mỗi trình duyệt trên mỗi thiết bị được điều chỉnh cho phù hợp với tùy chọn cookie của bạn.
Nếu bạn muốn dọn sạch tất cả các cookie bị bỏ lại bởi các trang web bạn đã truy cập, bạn có thể tìm thấy các trang web của bên thứ ba dọn sạch cookie theo dõi.
Các trang web của bên thứ ba này không thuộc quyền kiểm soát của chúng tôi và chúng tôi không đưa ra bất kỳ tuyên bố hay bảo đảm nào liên quan đến các trang web này. Nếu bạn quyết định truy cập các trang web của bên thứ ba này, chúng tôi khuyên bạn nên xem lại các điều khoản và điều kiện tương ứng và chính sách quyền riêng tư của bất kỳ trang web bên thứ ba nào trước khi bạn sử dụng trang web.`,
        },
        {
          title: `6. Thẻ pixel`,
          content: `Một số trang bạn truy cập trên Trang web cũng có thể thu thập thông tin thông qua việc sử dụng thẻ pixel <i>(còn được gọi là gifs rõ ràng)</i> có thể được chia sẻ với các bên thứ ba hỗ trợ trực tiếp cho các hoạt động quảng cáo và phát triển trang web của chúng tôi. Ví dụ: thông tin sử dụng trang web về khách truy cập vào trang web của chúng tôi có thể được chia sẻ với đại lý quảng cáo bên thứ ba của chúng tôi để nhắm mục tiêu quảng cáo biểu ngữ Internet tốt hơn trên trang web của chúng tôi. Tuy nhiên, thông tin được thu thập thông qua việc sử dụng các thẻ pixel này không phải là nhận dạng cá nhân, mặc dù nó có thể được liên kết với thông tin cá nhân của bạn.`,
        },
        {
          title: `7. Liên hệ với chúng tôi`,
          content: `Nếu bạn có bất kỳ câu hỏi nào về Chính sách cookie này, vui lòng liên hệ với chúng tôi tại <a href="mailto:info@amanaki.vn" style="color: #af915e">info@amanaki.vn</a>. Xin lưu ý rằng việc liên lạc qua email không phải lúc nào cũng an toàn, vì vậy vui lòng không bao gồm thông tin thẻ tín dụng hoặc thông tin nhạy cảm trong email của bạn cho chúng tôi.`,
        },
      ]),
    },
    bestRateGuaranteed: {
      heading: "Đảm bảo Giá tốt nhất",
      title: "ĐẶT PHÒNG TRỰC TIẾP VỚI AMANAKI ĐỂ CÓ GIÁ ĐẢM BẢO TỐT NHẤT.",
      data: JSON.stringify([
        {
          content: `Việc lựa chọn Amanaki sẽ là ưu tiên và niềm vinh hạnh của chúng tôi. Cuộc hành trình đó bắt đầu ngay khi bạn  đặt phòng trực tiếp trên trang web chính thức của chúng tôi. Bạn sẽ được đảm bảo:`,
        },
        {
          content: [
            `Mức giá thấp nhất có thể cho kỳ nghỉ của bạn`,
            `Các điều khoản hủy bỏ linh hoạt nhất`,
            `Tiếp cận với các chuyên gia đặt phòng tận tâm của chúng tôi để giúp bạn đặt phòng`,
            `Tiếp cận với dịch vụ tốt nhất trong suốt kỳ nghỉ của bạn`,
            `Nếu bạn nhận thấy tỷ lệ đủ điều kiện thấp hơn trong vòng 24 giờ sau khi đặt chỗ, chúng tôi sẽ linh động để phù hợp với tỷ lệ thấp hơn.`,
          ],
        },
      ]),
    },
  },
  HomeHotel10: {
    experiencing: {
      title: "Trải nghiệm Amanaki của quý khách",
    },
  },
  Offers: {
    offer: "Ưu đãi",
  },
  Offer: {
    valid: "Hiệu lực đến {{date}}",
    otherOffer: "Ưu đãi khác",
  },
  ContactUs: {
    description: `Hãy liên hệ với Amanaki nếu bạn cần thêm thông tin hay câu hỏi gì về các khách sạn, đặt phòng khách sạn hay liên hệ đặt hẹn với nhà hàng, wellbeing centre.`,
    getInTouch: "Giữ liên lạc",
    descInTouch: `Chúng tôi rất vui khi nhận được phản hồi từ bạn. Qúy khách vui lòng sử dụng mẫu bên dưới để liên hệ với chúng tôi.`,
    iConfirm:
      "Tôi xác nhận rằng tôi đã đọc và hiểu các điều khoản của khách sạn Amanaki",
    privacy: "Chính sách bảo mật",
    acceptReceiveInfomation:
      "Tôi muốn nhận thêm thông tin và các chương trình ưu đãi khuyến mãi từ Khách sạn Amanaki",
    addressAmanakiThaoDien: `Số 10, Nguyễn Đăng Giai, Thảo Điền, Thủ Đức,  Thành phố Hồ Chí Minh`,
    addressAmanakiSaigonBoutique: `65 Hồ Tùng Mậu, Phường Bến Nghé, Quận 1, Thành phố Hồ Chí Minh`,
  },
  Discover: {
    title: "Khám Phá Saigon",
    description:
      "“Mỗi tòa nhà, hay mỗi mặt tiền, dường như dẫn ta vào một thế giới khác, nén hết mọi sắc thái trong đời sống con người thành một tập hợp những giao kết càng xảy ra chỉ càng thêm phần phong phú.” <0/> Rebecca Solnit.",
    BlockLocation: {
      distanceAmanaki: "Đi từ Amanaki: ",
      checkMap: "Xem bản đồ",
    },
    BlockOurLocation: {
      ourLocation: "Vị trí của chúng tôi",
      getting: "Getting to Amanaki<0/>Thao Dien hotel",
      address: "10 Nguyễn Đăng Giai, Thảo Điền, Thủ Đức, Thành phố Hồ Chí Minh",
      phone: "+84(0)8 23624488",
      airport: "Sân bay quốc tế Tân Sơn Nhất",
      airportDescription: `Từ sân bay quốc tế Tân Sơn Nhất<0/>
Khoảng cách: 13 KM<0/>
Xe đưa đón sân bay: 500.000đ/lượt`,
      tel: "Sđt",
    },
  },
  Breadcrumb: {
    stories: "Câu Chuyện Của Chúng Tôi",
    category: "Danh mục",
    amanakiThaoDien: "Amanaki Thảo Điền",
    "amanaki-thao-dien": "Amanaki Thảo Điền",
    "amanaki-boutique-saigon": "Amanaki Saigon Boutique",
    theHotel: "Khách sạn",
  },
  Rooms: {
    Signature: "Phòng Signature",
    Executive: "Phòng Executive",
    Premium: "Phòng Premium",
    FamilySuite: "Căn hộ gia đình",
    otherRooms: "Phòng khác",
    theDetails: "Chi tiết",
    utilities: "Tiện ích",
    amenities: "Vật dụng trong phòng",
    checkIn: "Check-in",
    checkOut: "Check-out",
    visitOurRooms: "Thăm phòng",
    Banner: {
      title: "Thăm Phòng Của Chúng Tôi",
      description: `Hãy chậm lại và xem qua.<0/>Khi ở bên trong, một phần lịch sử đang dịu dàng nhìn bạn.`,
    },
  },
  DateFormat: {
    Default: "DD/MM/YYYY",
    FullDisplay: "HH:mm:ss - DD/MM/YYYY",
    Unit: {
      second: "giây",
      minute: "phút",
      hour: "giờ",
    },
    Time: "LT",
  },
  SearchControl: {
    room: "Phòng",
    adults: "Người lớn",
    children: "Trẻ em",
    bestPrice: "Đảm bảo giá tốt nhất",
    checkAvailability: "Kiểm tra phòng",
  },
  Time: {
    minutes: "{{n}} phút",
  },
  Filter: {
    clearFilter: "Xóa bộ lọc",
    categoryPlaceholder: "Category",
    placeholderCategory: "Danh mục",
    sortPlaceholder: "Sắp xếp",
    lastestLabel: "Bài mới nhất",
    oldestLabel: "Bài cũ nhất",
  },
};
export default vi;
