import Button from "@components/Button";
import styles from "./TextButton.module.scss";
import cx from "classnames";
import { useRouteContext } from "@providers/RouteProvider";
const View = ({ buttonName, text, buttonLink }: any) => {
  const { router } = useRouteContext();
  return (
    <div className={cx("inner-article", styles.section)}>
      <div className={cx("ama-content", styles.shortDescription)}>{text}</div>
      <div className="flex justify-center pt-[1.5rem]">
        <Button
          type="primary"
          color="black"
          label={buttonName}
          onClick={() => {
            router.open(buttonLink);
          }}
        />
      </div>
    </div>
  );
};
export default View;
