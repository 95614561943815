import _ from "lodash";
import service from "@utils/apiService";
const formatter = {
  id: (item: any) => {
    return _.get(item, "id") || "";
  },
  name: (item: any) => {
    return _.get(item, "attributes.name") || "";
  },
  slug: (item: any) => {
    return _.get(item, "attributes.slug") || "";
  },
  image: (item: any) => {
    return {
      src:
        service?.strapiUrl(
          _.get(item, "attributes.image.data.attributes.url")
        ) || "",
      alt:
        _.get(item, "attributes.image.data.attributes.alternativeText") || "",
    };
  },
};
export default formatter;
