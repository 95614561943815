import React, { Fragment } from "react";
import cx from "classnames";
import _ from "lodash";
import ModalProvider, {
  BoxModal,
  useModalContext,
} from "@providers/ModalProvider";
import { useTranslationContext } from "@providers/TranslationProvider";
import { useRouteContext } from "@providers/RouteProvider";
import { useLocomotiveContext } from "@providers/LocomotiveProvider";
import { usePageContext } from "@providers/PageProvider";

import styles from "./Header.module.scss";
import Link from "@components/Link";

import MenuModal from "@components/MenuModal";
import MenuHamburger from "@components/MenuHamburger";
import LogoVertical from "@components/Logo/VerticalYellow";
import Search from "@components/Icons/Search";
import Dropdown from "@components/Dropdown";
import Button from "@components/Button";
import Icons from "@gstyles/styleguide/icons";
import { NAVS } from "@utils/constant";
import renderPropsComposer from "@utils/renderPropsComposer";
import { getCurrentLocaleConfig } from "@translations/util";
import { Dropdown as BFDropdown } from "@components/BookingForm";

function Header() {
  const ref: any = React.useRef({ static: true });
  const { modalService } = useModalContext();
  const { i18n } = useTranslationContext();
  const { hotel: hotelPage } = usePageContext();
  const { router } = useRouteContext();
  const { scroll } = useLocomotiveContext();
  const MENU_NAVS = _.get(NAVS, `${i18n.language}.${hotelPage || "group"}`);
  const MENU_GROUP = _.get(NAVS, `${i18n.language}.${"group"}`);
  const NAV = [
    {
      title: i18n.t("Header.contactUs"),
      link: router.toUrl("contact"),
    },
  ];
  const [hotel] = React.useState(hotelPage);
  const [switched, $switched]: any = React.useState("");

  const [language, $language] = React.useState(i18n.language);
  const isGroup = !hotelPage || hotelPage === "group";
  _.assign(ref.current, {
    switched,
    $switched,
  });
  React.useEffect(() => {
    if (scroll) {
      const header: any = document.getElementById("header");
      let headerHeight = header.getBoundingClientRect().height;
      const breakpoint = 400;
      const handlerScroll: any = {
        up: (instance: any) => {
          if (
            !ref.current.static &&
            instance.scroll.y <= breakpoint - headerHeight &&
            _.isEqual(ref.current.switched, styles.switched)
          ) {
            ref.current.$switched(false);
            ref.current.static = true;
          }
        },
        down: (instance: any) => {
          if (
            ref.current.static &&
            instance.scroll.y > breakpoint - headerHeight &&
            !_.isEqual(ref.current.switched, styles.switched)
          ) {
            ref.current.$switched(styles.switched);
            ref.current.static = false;
          }
        },
      };
      scroll.on("scroll", (instance: any) => {
        if (_.has(handlerScroll, instance.direction) && !isGroup) {
          handlerScroll[instance.direction](instance);
        }
      });
    }
  }, [scroll]);
  return (
    <div className="fixed z-50 w-full">
      <div className={cx(styles.menuIcon)}>
        <BoxModal
          modal={
            <MenuModal NAVS={MENU_NAVS} MENU_GROUP={MENU_GROUP} hotel={hotel} />
          }
          Comp={(props) => {
            return (
              <>
                <MenuHamburger {...props} />
              </>
            );
          }}
        />
      </div>
      <header
        className={cx(styles.header, "flex flex-col", ref.current.switched, {
          [styles.isGroup]: isGroup,
          [styles.toTop]: _.get(modalService, "visible"),
        })}
        id="header"
      >
        <div>
          <div className={styles.headerBg}>
            <div
              className={cx(styles.headerChild, "inner-2 flex justify-between")}
            >
              <div className="flex">
                <div
                  className={cx("cursor-pointer", styles.amanakiGroup)}
                  onClick={() => {
                    router.push("home");
                  }}
                >
                  <span className="amin-underline">
                    {i18n.t("NAVS.amanakiHotel")}
                  </span>
                </div>
                <div className={styles.border}></div>
                <Dropdown
                  amin={true}
                  className={styles.hotels}
                  itemProps={{ className: styles.label }}
                  value={hotel}
                  placeholder={i18n.t("Header.selectAHotel")}
                  onChange={(opt: any) => {
                    // $hotel(opt.value ?? "");
                    const handler: any = {
                      "amanaki-thao-dien": () => {
                        router.push("amanaki-thao-dien");
                      },
                      "amanaki-hotels": () => {
                        router.push("home");
                      },
                      "amanaki-boutique-saigon": () => {
                        router.push("amanaki-boutique-saigon");
                      },
                    };
                    if (_.has(handler, opt.routeName)) handler[opt.routeName]();
                  }}
                  options={[
                    {
                      label: i18n.t("Footer.thaoDien"),
                      value: "amanaki-thao-dien",
                      routeName: "amanaki-thao-dien",
                    },
                    {
                      label: i18n.t("Footer.boutiqueSaigon"),
                      value: "amanaki-boutique-saigon",
                      routeName: "amanaki-boutique-saigon",
                    },
                  ]}
                />
              </div>
              <div
                className="flex text-xs uppercase"
                style={{ fontWeight: "450", columnGap: "16px" }}
              >
                <Dropdown
                  amin={true}
                  itemProps={{ className: styles.label }}
                  placeholder={i18n.t("Header.aboutAmanaki")}
                  options={[
                    {
                      label: i18n.t("NAVS.aboutUs"),
                      value: "about-us",
                    },
                    {
                      label: i18n.t("NAVS.sustainability"),
                      value: "about-sustainability",
                    },
                    {
                      label: i18n.t("NAVS.careers"),
                      value: "about-careers",
                    },
                  ]}
                  optionsProps={{ className: styles.options }}
                  optionProps={{ className: styles.option }}
                  // value={language}
                  onChange={(opt: any) => router.push(opt.value)}
                />
                {_.map(NAV, (nav, idx) => (
                  <Fragment key={`nav_${idx}`}>
                    <div className="flex items-center">
                      <Link className="amin-underline" href={nav.link}>
                        {nav.title}
                      </Link>
                    </div>
                    {idx === 0 && <span className="flex items-center">|</span>}
                  </Fragment>
                ))}
                <Dropdown
                  className={styles.language}
                  optionsProps={{ className: styles.options }}
                  optionProps={{ className: styles.option }}
                  // amin={true}
                  itemProps={{ className: styles.label }}
                  options={[
                    {
                      label: (
                        <Icons
                          name="flag-en"
                          size={20}
                          alt="amanaki-language-en"
                        />
                      ),
                      value: "en",
                    },
                    {
                      label: (
                        <Icons
                          name="flag-vn"
                          size={20}
                          alt="amanaki-language-vi"
                        />
                      ),
                      value: "vi",
                    },
                  ]}
                  value={language}
                  onChange={(opt: any) => {
                    $language(opt.value);
                    const localeInstance = getCurrentLocaleConfig();
                    localeInstance.setLanguage(opt.value);
                    const baseUrl = localeInstance.withLangPrefix(
                      `${_.get(router, "location.pathname")}${_.get(
                        router,
                        "location.search"
                      )}`
                    );
                    router.languageTo(baseUrl);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={cx(
            styles.headerChild2,
            "inner-child flex justify-between items-center relative"
          )}
        >
          <MenuHamburger className={styles.icon} />

          {/* <Menu /> */}

          {/* <LogoHorizontal /> */}
          <Link
            style={{
              position: "absolute",
              right: "50%",
              transform: "translateX(50%)",
            }}
            href={router.toUrl("home")}
            ariaLabel="amanaki logo"
          >
            <LogoVertical />
          </Link>
          <Search className={cx(styles.mobile, styles.right)} />
          <BFDropdown
            className={styles.dropdown}
            itemProps={{ className: styles.label }}
            optionsProps={{ className: styles.options }}
            optionProps={{ className: styles.option }}
            label={
              <Button
                className={styles.desktop}
                label={i18n.t("Button.bookNow")}
              />
            }
          />
        </div>
        {!!(hotel && hotel !== "group" && !_.isEmpty(MENU_NAVS)) && (
          <div className={styles.animBorderTop}>
            <div
              className={cx(
                "inner-child flex justify-center items-center",
                styles.navs
              )}
            >
              {_.map(_.get(MENU_NAVS, "data", []), (menu: any, index: number) =>
                renderPropsComposer(
                  {
                    matcher: (menu: any) => menu.type === "select",
                    render: (menu: any) => {
                      return (
                        <Fragment key={`navs_more_${index}`}>
                          <Dropdown
                            className={styles.navDropdown}
                            itemProps={{ className: styles.label }}
                            {...menu}
                            optionsProps={{ className: styles.options }}
                            optionProps={{ className: styles.option }}
                            onChange={(opt: any) => {
                              router.push(opt.value);
                            }}
                            amin={true}
                          />
                          {!!(
                            index !==
                            _.get(MENU_NAVS, "data.length", 0) - 1
                          ) && <div className={styles.point}></div>}
                        </Fragment>
                      );
                    },
                  },
                  {
                    matcher: (menu: any) => menu.type === "link",
                    render: (menu: any) => {
                      return (
                        <Fragment key={`navs_more_${index}`}>
                          <Link
                            className="uppercase amin-underline"
                            href={router.toUrl(
                              _.get(menu, "routeName", "home")
                            )}
                            label={_.get(menu, "label", "")}
                          />
                          {!!(
                            index !==
                            _.get(MENU_NAVS, "data.length", 0) - 1
                          ) && <div className={styles.point}></div>}
                        </Fragment>
                      );
                    },
                  }
                )(menu)
              )}
            </div>
          </div>
        )}
      </header>
    </div>
  );
}
const View = (props: any) => {
  return (
    <ModalProvider>
      <Header {...props} />
    </ModalProvider>
  );
};
export default View;
