import React from "react";
import Markdown from "@components/Markdown";
import styles from "./TextTitleBullet.module.scss";
import cx from "classnames";
import _ from "lodash";

const components = {
  li: (props: any) => (
    <div {...props} className={cx("ama-content text-black-1", styles.bullet)} />
  ),
};
const View = ({ title, description }: any) => {
  return (
    <section className={cx("inner-large", styles.section)}>
      <div className={cx("flex", styles.box)}>
        <div className={cx("ama-title text-black-1", styles.title)}>
          {title}
        </div>
        <ul className={styles.list}>
          <Markdown components={components} content={description} />
        </ul>
      </div>
    </section>
  );
};
export default View;
