export const OPPOSITE_POSITION: any = {
  left: "right",
  right: "left",
  topLeft: "bottomRight",
  topRight: "bottomLeft",
  bottomLeft: "topRight",
  bottomRight: "topLeft",
};
export const HOTEL_CODES: any = {
  "amanaki-thao-dien": "491275",
  "amanaki-boutique-saigon": "70AS04",
};
export const NAVS: any = {
  en: {
    group: {
      title: "Amanaki Hotels",
      data: [
        {
          label: "Home",
          href: "home",
        },
        {
          label: "About Amanaki",
          src: "/home/about.jpg",
          child: [
            {
              label: "About Us",
              href: "about-us",
            },
            {
              label: "Sustainbility",
              href: "about-sustainability",
            },
            {
              label: "Carreers",
              href: "about-careers",
            },
          ],
        },
        {
          label: "Our Hotels",
          subLabel: "Discover Our Hotels",
          src: "/home/about.jpg",
          child: [
            {
              label: "Amanaki Saigon Boutique",
              href: "amanaki-boutique-saigon",
            },
            {
              label: "Amanaki Thao Dien",
              href: "amanaki-thao-dien",
            },
          ],
        },
        {
          label: "Contact Us",
          href: "contact",
        },
      ],
    },
    "amanaki-thao-dien": {
      title: "Amanaki Thao Dien",
      data: [
        {
          label: "home",
          routeName: "amanaki-thao-dien",
          href: "amanaki-thao-dien",
          type: "link",
        },
        {
          src: "/home/about.jpg",
          options: [
            {
              label: "brand story",
              value: "brand-story",
            },
            {
              label: "arts & Amanaki",
              value: "arts",
            },
          ],
          child: [
            {
              label: "brand story",
              href: "brand-story",
            },
            {
              label: "arts & Amanaki",
              href: "arts",
            },
          ],
          label: "the hotel",
          type: "select",
        },
        {
          label: "rooms",
          routeName: "rooms",
          href: "rooms",
          type: "link",
        },
        {
          label: "wellness",
          routeName: "wellness",
          href: "wellness",
          type: "link",
        },
        {
          label: "dining",
          routeName: "dinning",
          href: "dinning",
          type: "link",
        },
        {
          label: "events",
          routeName: "meeting-and-events",
          href: "meeting-and-events",
          type: "link",
        },
        {
          label: "offers",
          routeName: "offers",
          href: "offers",
          type: "link",
        },
        {
          label: "blog",
          routeName: "stories",
          href: "stories",
          type: "link",
        },
        {
          label: "Discover Saigon",
          routeName: "discover",
          href: "discover",
          type: "link",
        },
      ],
    },
    "amanaki-boutique-saigon": {
      title: "Amanaki Boutique Saigon",
      data: [
        {
          label: "home",
          routeName: "amanaki-boutique-saigon",
          href: "amanaki-boutique-saigon",
          type: "link",
        },
        {
          label: "the hotel",
          type: "link",
          routeName: "theHotel",
          href: "theHotel",
        },
        {
          label: "rooms",
          routeName: "rooms",
          href: "rooms",
          type: "link",
        },
        {
          label: "Facilities & Service",
          routeName: "facilities-service",
          href: "facilities-service",
          type: "link",
        },
        {
          label: "offers",
          routeName: "offers",
          href: "offers",
          type: "link",
        },
        {
          label: "blog",
          routeName: "stories",
          href: "stories",
          type: "link",
        },
        {
          label: "Discover Saigon",
          routeName: "discover",
          href: "discover",
          type: "link",
        },
      ],
    },
  },
  vi: {
    group: {
      title: "Amanaki Hotels",
      data: [
        {
          label: "Trang Chủ",
          href: "home",
        },
        {
          label: "Về Amanaki",
          src: "/home/about.jpg",
          child: [
            {
              label: "Giới thiệu",
              href: "about-us",
            },
            {
              label: "Phát triển bền vững",
              href: "about-sustainability",
            },
            {
              label: "Nghề nghiệp",
              href: "about-careers",
            },
          ],
        },
        {
          label: "Các khách sạn",
          subLabel: "Khám phá khách sạn",
          src: "/home/about.jpg",
          child: [
            {
              label: "Amanaki Sài Gòn Boutique",
              href: "amanaki-boutique-saigon",
            },
            {
              label: "Amanaki Thảo Điền",
              href: "amanaki-thao-dien",
            },
          ],
        },
        {
          label: "Liên hệ Amanaki",
          href: "contact",
        },
      ],
    },
    "amanaki-thao-dien": {
      title: "Amanaki Thảo Điền",
      data: [
        {
          label: "Trang chủ",
          routeName: "amanaki-thao-dien",
          href: "amanaki-thao-dien",
          type: "link",
        },
        {
          src: "/home/about.jpg",
          options: [
            {
              label: "Câu chuyện Amanaki Thảo Điền",
              value: "brand-story",
            },
            {
              label: "Nghệ thuật và Amanaki",
              value: "arts",
            },
          ],
          child: [
            {
              label: "Câu chuyện Amanaki Thảo Điền",
              href: "brand-story",
            },
            {
              label: "Nghệ thuật và Amanaki",
              href: "arts",
            },
          ],
          label: "Khách sạn",
          type: "select",
        },
        {
          label: "Phòng nghỉ",
          routeName: "rooms",
          href: "rooms",
          type: "link",
        },
        {
          label: "Sức khỏe",
          routeName: "wellness",
          href: "wellness",
          type: "link",
        },
        {
          label: "Nhà hàng",
          routeName: "dinning",
          href: "dinning",
          type: "link",
        },
        {
          label: "Sự kiện",
          routeName: "meeting-and-events",
          href: "meeting-and-events",
          type: "link",
        },
        {
          label: "Ưu đãi",
          routeName: "offers",
          href: "offers",
          type: "link",
        },
        {
          label: "Bài viết",
          routeName: "stories",
          href: "stories",
          type: "link",
        },
        {
          label: "Khám phá Sài Gòn",
          routeName: "discover",
          href: "discover",
          type: "link",
        },
      ],
    },
    "amanaki-boutique-saigon": {
      title: "Amanaki Sài Gòn Boutique",
      data: [
        {
          label: "Trang chủ",
          routeName: "amanaki-boutique-saigon",
          href: "amanaki-boutique-saigon",
          type: "link",
        },
        {
          label: "Khách sạn",
          type: "link",
          href: "theHotel",
          routeName: "theHotel",
        },
        {
          label: "Phòng nghỉ",
          routeName: "rooms",
          href: "rooms",
          type: "link",
        },
        {
          label: "Cơ sở vật chất & Dịch vụ",
          routeName: "facilities-service",
          href: "facilities-service",
          type: "link",
        },
        {
          label: "Ưu đãi",
          routeName: "offers",
          href: "offers",
          type: "link",
        },
        {
          label: "Bài viết",
          routeName: "stories",
          href: "stories",
          type: "link",
        },
        {
          label: "Khám phá Sài Gòn",
          routeName: "discover",
          href: "discover",
          type: "link",
        },
      ],
    },
  },
};
export const MENU_NAVS = [
  {
    placeholder: "the hotel",
    options: [
      {
        label: "Brand story",
        value: "brand-story",
      },
      {
        label: "Arts and Amanaki",
        value: "arts",
      },
    ],
    type: "select",
  },
  {
    label: "rooms",
    routeName: "rooms",
    type: "link",
  },
  {
    label: "wellness",
    routeName: "wellness",
    type: "link",
  },
  {
    label: "dinning",
    routeName: "dinning",
    type: "link",
  },
  {
    label: "events",
    routeName: "meeting-and-events",
    type: "link",
  },
  {
    label: "offers",
    routeName: "offers",
    type: "link",
  },
  {
    label: "blog",
    routeName: "stories",
  },
  {
    label: "discover saigon",
    routeName: "discover",
    type: "link",
  },
];
