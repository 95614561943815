const View = () => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5617_33836)">
        <path
          d="M25.9057 12C25.9057 5.37188 20.1501 0 13.0485 0C5.94699 0 0.191406 5.37188 0.191406 12C0.191406 18.6281 5.94699 24 13.0485 24C13.1239 24 13.1992 24 13.2746 23.9953V14.6578H10.5123V11.6531H13.2746V9.44062C13.2746 6.87656 14.952 5.47969 17.4029 5.47969C18.5781 5.47969 19.5876 5.55938 19.8789 5.59688V8.27813H18.1914C16.8605 8.27813 16.5993 8.86875 16.5993 9.73594V11.6484H19.7885L19.3717 14.6531H16.5993V23.5359C21.9732 22.0969 25.9057 17.4797 25.9057 12Z"
          fill="#F7EEDF"
        />
      </g>
      <defs>
        <clipPath id="clip0_5617_33836">
          <rect
            width="25.7143"
            height="24"
            fill="white"
            transform="translate(0.191406)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default View;
