import _ from "lodash";
import { qs } from "@utils/apiService";
import articleGetter from "@services/StoryArticleService/getter";
import offerGetter from "@services/OfferService/getter";
import categoryGetter from "@services/StoryCategoryService/getter";
import strapiGetter from "@services/StrapiService/getter";
import roomGetter from "@services/RoomService/getter";
import locationGetter from "@services/LocationService/getter";
import tourGetter from "@services/TourService/getter";
import {
  formatDateDisplay,
  formatDateMonth,
  formatTimeDinning,
} from "@utils/dateFormatter";
import i18n from "@translations/i18n";
const helper = {
  sleep: (ms: number) => new Promise((res) => setTimeout(res, ms)),
  isInternal: (to: string) => {
    return /^\/(?!\/)/.test(to);
  },
  isExternal: (val: string) => {
    return `${val}`.substring(0, 4) === "http";
  },
  normalizeObject: (obj: object) => {
    return _(obj)
      .omitBy(_.isUndefined)
      .omitBy((val) => val === "")
      .omitBy((val: []) => _.isArray(val) && val.length === 0)
      .value();
  },
  normalizeInventory: (inventories: any = []) => {
    return _.map(inventories, (item) =>
      helper.normalizeObject({
        id: _.get(item, "id"),
        warehouse: _.get(item, "warehouse.id") || _.get(item, "warehouse"),
        quantity: _.get(item, "quantity"),
      })
    );
  },
  flattenKeys: (obj = {}) => {
    const result: any = {};

    const flatten = (collection: object, prefix = "", suffix = "") => {
      _.forEach(collection, (value, key) => {
        const path = `${prefix}${key}${suffix}`;

        if (_.isArray(value)) {
          flatten(value, `${path}[`, "]");
        } else if (_.isPlainObject(value)) {
          flatten(value, `${path}.`);
        } else {
          result[path] = value;
        }
      });
    };

    flatten(obj);

    return result;
  },
  replaceAll: (str: string = "", find: string, replace: string) => {
    if (!_.isString(str)) return "";
    return str.split(find).join(replace);
  },
  range: (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, index) => index + start);
  },
  popupWindow: (
    url: string,
    windowName: string,
    win: any,
    w: number,
    h: number
  ) => {
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;

    const newwindow = win.open(
      url,
      windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${0}, left=${x}`
    );
    if (_.isEmpty(window.focus)) {
      newwindow.focus();
    }
    return newwindow;
  },
  getBookingLink: (params: any = {}) => {
    if (_.isEmpty(params))
      return `https://booking.ezcms.vn/hotel/BeDetailHotel?hotel_code=491275&currency=VND&lang=en`;
    return qs.stringify(`https://booking.ezcms.vn/hotel/BeDetailHotel`, params);
    // https://booking.ezcms.vn/hotel/BeDetailHotel?check_in=2023-05-05&check_out=2023-05-07&num_of_rooms=1&num_of_adults=2&num_of_children=0&lang=en&childrenAges=6&currency=VND
  },
  renderDetailsFooter: (hotelSlug: string) => {
    let footer = {
      name: "",
      address: "",
      email: "",
      phone: "",
    };
    switch (hotelSlug) {
      case "amanaki-thao-dien":
        footer.name = i18n.t("Footer.thaoDien");
        footer.address = i18n.t("Footer.address10");
        footer.email = i18n.t("Footer.email10");
        footer.phone = i18n.t("Footer.phone10");
        break;
      case "amanaki-boutique-saigon":
        footer.name = i18n.t("Footer.boutiqueSaigon");
        footer.address = i18n.t("Footer.address65");
        footer.email = i18n.t("Footer.email65");
        footer.phone = i18n.t("Footer.phone65");
        break;
      default: {
        footer.name = i18n.t("Footer.name");
        footer.address = i18n.t("Footer.address");
        footer.email = i18n.t("Footer.email");
        footer.phone = i18n.t("Footer.phone");
      }
    }
    return footer;
  },
  formatArticleItem: (data: any) => {
    return {
      topic: articleGetter.category(data).name,
      image: articleGetter.image(data),
      date: formatDateDisplay(articleGetter.publishedAt(data)),
      title: articleGetter.title(data),
      description: articleGetter.description(data),
      slug: articleGetter.slug(data),
    };
  },
  formatOfferItem: (data: any) => {
    return {
      title: offerGetter.title(data),
      date: formatDateDisplay(offerGetter.publishedAt(data)),
      expiryDate: formatDateDisplay(offerGetter.expiryDate(data)),
      image: offerGetter.image(data),
      description: offerGetter.description(data),
      slug: offerGetter.slug(data),
      category: offerGetter.category(data),
    };
  },
  formatCategory: (data: any) => {
    return !!(categoryGetter.name(data) && categoryGetter.slug(data))
      ? {
          name: categoryGetter.name(data),
          slug: categoryGetter.slug(data),
        }
      : null;
  },
  formatBannerSlider: (data: any) => {
    const { slides, isAuto, interval } = strapiGetter.bannerSlider(data);
    return {
      isAuto,
      interval,
      slides: _.map(slides, (slide) => {
        return {
          title: _.get(slide, "title"),
          image: strapiGetter.image(_.get(slide, "image", {})),
        };
      }),
    };
  },
  formatBanner: (data: any) => {
    const pageData = strapiGetter.banner(data);
    return {
      title: _.get(pageData, "title", ""),
      description: _.get(pageData, "description", ""),
      content: _.get(pageData, "content", ""),
      image: strapiGetter.image(_.get(pageData, "image", {})),
    };
  },

  formatAboutUsData: (data: any) => {
    const {
      displayType,
      youtubeVideoEmbedLink,
      content,
      readMoreButton,
      aboveImage,
      belowImage,
    } = strapiGetter.aboutUs(data);
    return {
      displayType: displayType ?? "image",
      youtubeVideoEmbedLink,
      aboveImage: strapiGetter.image(aboveImage),
      belowImage: strapiGetter.image(belowImage),
      content,
      readMoreButton: strapiGetter.button(readMoreButton),
    };
  },
  formatVisitRoomData: (data: any) => {
    const { title, readMoreButton, rooms } = strapiGetter.visitRoom(data);
    return {
      title: title,
      readMoreButton: strapiGetter.button(readMoreButton),
      rooms: _.map(_.get(rooms, "data"), (item) => ({
        label: roomGetter.name(item),
        value: roomGetter.slug(item),
        slug: roomGetter.slug(item),
        images: {
          image: roomGetter.image(
            _.get(item, "attributes.thumbnailBackground")
          ),
          image2: roomGetter.image(_.get(item, "attributes.thumbnail")),
          // image2: {},
        },
        general: [roomGetter.description(item)],
        highlight: [
          {
            icon: "square-area-outline",
            value: roomGetter.size(item),
          },
          {
            icon: "twin-beds-outline",
            value: roomGetter.bedInfo(item),
          },
          {
            type: "capacity",
            icon: "two-users-outline",
            value: roomGetter.capacity(item),
          },
          {
            icon: "eye-outline",
            value: roomGetter.location(item),
          },
        ],
      })),
    };
  },
  // formatHotelAddress: (data: any) => {
  //   const { ...props } = strapiGetter.hotelAddress(data);

  // },
  formatVisitRooms: (data: any) => {
    // const pageData = strapiGetter.visitRooms(data);
    return _.map(data, (item) => ({
      label: roomGetter.name(item),
      value: roomGetter.slug(item),
      slug: roomGetter.slug(item),
      images: {
        image: roomGetter.image(_.get(item, "attributes.thumbnail")),
        image2: roomGetter.image(_.get(item, "attributes.thumbnailBackground")),
        // image2: {},
      },
      general: [roomGetter.description(item)],
      highlight: [
        {
          icon: "square-area-outline",
          value: roomGetter.size(item),
        },
        {
          icon: "twin-beds-outline",
          value: roomGetter.bedInfo(item),
        },
        {
          type: "capacity",
          icon: "two-users-outline",
          value: roomGetter.capacity(item),
        },
        {
          icon: "eye-outline",
          value: roomGetter.location(item),
        },
      ],
    }));
  },
  formatExperiencingAmanaki: (data: any) => {
    const pageData = strapiGetter.experiencingAmanaki(data);

    return {
      title: _.get(pageData, "title", ""),
      isAuto: _.get(pageData, "slider.isAuto", ""),
      interval: _.get(pageData, "slider.interval", ""),
      slides: _.map(_.get(pageData, "slider.slides", []), (slide) => ({
        title: _.get(slide, "title"),
        content: _.get(slide, "description"),
        image: strapiGetter.image(_.get(slide, "image")),
        readMoreButton: strapiGetter.button(_.get(slide, "readMoreButton")),
      })),
    };
  },
  formatExploreArts: (data: any) => {
    const pageData = strapiGetter.exploreArts(data);
    return {
      description: _.get(pageData, "description", ""),
      image: strapiGetter.image(_.get(pageData, "image")),
      readMoreButton: strapiGetter.button(_.get(pageData, "readMoreButton")),
    };
  },
  formatStories: (data: any) => {
    const pageData = strapiGetter.stories(data);
    return {
      title: _.get(pageData, "title", ""),
      readMoreButton: strapiGetter.button(_.get(pageData, "readMoreButton")),
    };
  },
  formatOffers: (data: any) => {
    const pageData = strapiGetter.offers(data);
    return {
      title: _.get(pageData, "title", ""),
      readMoreButton: strapiGetter.button(_.get(pageData, "readMoreButton")),
    };
  },
  formatStayConnected: (data: any) => {
    const pageData = strapiGetter.stayConnected(data);
    return {
      title: _.get(pageData, "title", ""),
      description: _.get(pageData, "description", ""),
      icons: _.map(_.get(pageData, "listIcons"), strapiGetter.icon),
      images: _.map(_.get(pageData, "listImages"), (item) => ({
        icon: strapiGetter.icon(_.get(item, "iconData")),
        image: strapiGetter.image(_.get(item, "image")),
      })),
    };
  },
  formatMetatags: (data: any) => {
    const pageData = strapiGetter.metatags(data);
    return {
      metaTitle:
        _.get(pageData, "title", "") ||
        "Khách sạn Amanaki -  Khách sạn Boutique tại Sài Gòn | Việt Nam",
      // metaUrl: _.get(router, "location.href", ""),
      metaDescription:
        _.get(pageData, "description", "") ||
        "Khách sạn boutique tại Thành phố Hồ Chí Minh với nguồn cảm hứng từ  hạnh phúc, cân bằng và bền vững. Hãy đặt kỳ nghỉ của bạn tại Amanaki, trải nghiệm Sài Gòn, kết nối trở lại và khám phá niềm hạnh phúc dựa trên tâm - thân - trí.",
      metaImage:
        strapiGetter.image(_.get(pageData, "image", ""))?.src ||
        "https://amanaki.vn/seo/Amanaki Hotels Ho Chi Minh City.jpg",
      metaType: "amanaki-website",
      metaKeywords:
        _.get(pageData, "tags", "") ||
        "Khách sạn Thành phố Hồ Chí Minh, Sài Gòn, phát triển bền vững, văn hóa Việt Nam, truyền thống Vietnam, sức khỏe thể chất, sức khỏe tinh thần",
    };
  },
};
const offerPage = {
  formatInformation: (data: any) => {
    const pageData = strapiGetter.exclusiveOffer(data);
    return {
      title: _.get(pageData, "title"),
      description: _.get(pageData, "description"),
      button: strapiGetter.button(_.get(pageData, "actionButton")),
    };
  },
};
const offerDetailPage = {
  formatBanner: (data: any) => {
    return {
      title: _.get(data, "attributes.title", ""),
      description: _.get(data, "attributes.description", ""),
      image: strapiGetter.image(_.get(data, "attributes.images.data.0", {})),
    };
  },
};
const artPage = {
  formatFeatured: (data: any) => {
    const pageData = strapiGetter.featured(data);
    return {
      quoteContent: _.get(pageData, "quoteContent", ""),
      quoteSource: _.get(pageData, "quoteSource", ""),
      content: _.get(pageData, "content", ""),
      image: strapiGetter.image(_.get(pageData, "image", {})),
      // paragraph: _.get(pageData, "paragraph", ""),
      // heading: _.get(pageData, "headingText", ""),
      // headingBackground: strapiGetter.image(
      //   _.get(pageData, "headingBackground", {})
      // ),
      // leftImage: strapiGetter.image(_.get(pageData, "leftImage", {})),
      // rightImage: strapiGetter.image(_.get(pageData, "rightImage", {})),
      // bottomRightImage: strapiGetter.image(
      //   _.get(pageData, "bottomRightImage", {})
      // ),
      // bottomContent: _.get(pageData, "bottomContent", ""),
      // bottomBackground: strapiGetter.image(
      //   _.get(pageData, "bottomBackground", {})
      // ),
    };
  },
  formatCollection: (data: any) => {
    const pageData = strapiGetter.collection(data);
    return {
      title: _.get(pageData, "title", ""),
      images: _.map(_.get(pageData, "images.data", []), strapiGetter.image),
    };
  },
  formatParagraph: (data: any) => {
    const pageData = strapiGetter.paragraph(data);
    return {
      ..._.pick(pageData, ["title", "content"]),
      headingBackground: strapiGetter.image(
        _.get(pageData, "headingBackground")
      ),
      background: strapiGetter.image(_.get(pageData, "background")),
    };
  },
  formatSpotlight: (data: any) => {
    const pageData = strapiGetter.spotlight(data);
    return {
      title: _.get(pageData, "title", ""),
      articles: _.map(_.get(pageData, "articles", []), (item) => ({
        title: articleGetter.title(_.get(item, "article.data", {})),
        content: articleGetter.description(_.get(item, "article.data", {})),
        slug: articleGetter.slug(_.get(item, "article.data", {})),
        image: articleGetter.image(_.get(item, "article.data", {})),
        button: strapiGetter.button(_.get(item, "actionButton", {})),
      })),
    };
  },
  formatExhibition: (data: any) => {
    const pageData = strapiGetter.exhibition(data);
    return {
      title: _.get(pageData, "title", ""),
      description: _.get(pageData, "description", ""),
      upcoming: _.get(pageData, "upcoming", []),
      current: _.get(pageData, "current", []),
      previous: _.get(pageData, "previous", []),
    };
  },
  formatExhibitionArticles: (data: any, type: string) => {
    return _.map(data, (item) => ({
      title: articleGetter.title(_.get(item, "article.data", {})),
      subtitle:
        type == "upcoming"
          ? `${formatDateMonth(
              articleGetter.startDate(_.get(item, "article.data", {}))
            )} - ${formatDateMonth(
              articleGetter.endDate(_.get(item, "article.data", {}))
            )}`
          : type == "current"
          ? i18n.t("Arts.exhibitions.status.ongoing")
          : i18n.t("Arts.exhibitions.status.exhibition"),
      content: articleGetter.description(_.get(item, "article.data", {})),
      slug: articleGetter.slug(_.get(item, "article.data", {})),
      image: articleGetter.image(_.get(item, "article.data", {})),
      button: strapiGetter.button(_.get(item, "actionButton", {})),
    }));
  },
};
const brandStoryPage = {
  formatFeatured: (data: any) => {
    const pageData = strapiGetter.featured(data);
    return {
      content: _.get(pageData, "quoteContent", ""),
      quote: _.get(pageData, "quoteSource", ""),
      image: strapiGetter.image(_.get(pageData, "bottomQuoteBackground", {})),
      bottomContent: _.get(pageData, "bottomQuoteContent", ""),
      bottomQuote: _.get(pageData, "bottomQuoteSoucre", ""),
    };
  },
  formatExplanation: (data: any) => {
    const pageData = strapiGetter.explanation(data);
    return {
      content: _.get(pageData, "content", ""),
      image: strapiGetter.image(_.get(pageData, "image", {})),
      background: strapiGetter.image(_.get(pageData, "backgroundImage", {})),
    };
  },
  formatParagraphs: (data: any) => {
    const pageData = strapiGetter.paragraphs(data);
    return _.map(pageData, (item) => ({
      ..._.pick(item, ["content"]),
      image: strapiGetter.image(_.get(item, "image", {})),
    }));
  },
  formatLogo: (data: any) => {
    const pageData = strapiGetter.amanakiLogo(data);
    return strapiGetter.image(pageData);
  },
};
const dinningPage = {
  formatSliderSection: (data: any) => {
    const pageData = strapiGetter.sliderSection(data);
    return {
      title: _.get(pageData, "title", ""),
      slider: {
        isAuto: _.get(pageData, "slider.isAuto"),
        interval: _.get(pageData, "slider.interval"),
        slides: _.map(
          _.get(pageData, "slider.slides.data", []),
          strapiGetter.image
        ),
      },
    };
  },

  formatRestaurant: (data: any) => {
    const pageData = strapiGetter.restaurant(data);
    return {
      title: _.get(pageData, "title", ""),
      description: _.get(pageData, "description", ""),
      image: strapiGetter.image(_.get(pageData, "image", {})),
    };
  },
  formatCuisine: (data: any) => {
    const pageData = strapiGetter.cuisine(data);
    return {
      title: _.get(pageData, "title", ""),
      description: _.get(pageData, "description", ""),
      slider: {
        isAuto: _.get(pageData, "slider.isAuto"),
        interval: _.get(pageData, "slider.interval"),
        slides: _.map(
          _.get(pageData, "slider.slides.data", []),
          strapiGetter.image
        ),
      },
    };
  },
  formatRestaurantInformation: (data: any) => {
    const pageData = strapiGetter.restaurantInformation(data);
    return {
      cuisine: {
        ..._.pick(_.get(pageData, "cuisine"), ["title", "value"]),
        button: strapiGetter.button(_.get(pageData, "cuisine.actionButton")),
      },
      headChef: _.pick(_.get(pageData, "headChef"), ["title", "value"]),
      moreInfor: {
        ..._.pick(_.get(pageData, "moreInfor"), ["title"]),
        button: strapiGetter.button(_.get(pageData, "moreInfor.actionButton")),
      },
      openingHours: {
        ..._.pick(_.get(pageData, "openingHours"), [
          "openTime",
          "closeTime",
          "note",
        ]),
        openTime: formatTimeDinning(
          _.get(pageData, "openingHours.openTime", "")
        ),
        closeTime: formatTimeDinning(
          _.get(pageData, "openingHours.closeTime", "")
        ),
      },
      reservation: _.pick(_.get(pageData, "reservation"), [
        "title",
        "phone",
        "email",
        "address",
      ]),
    };
  },
  formatHeadChefSection: (data: any) => {
    const pageData = strapiGetter.headChefSection(data);
    return {
      ..._.pick(pageData, ["title", "description"]),
      image: strapiGetter.image(_.get(pageData, "image")),
      button: strapiGetter.button(_.get(pageData, "readMoreButton")),
    };
  },
  formatExperience: (data: any) => {
    const pageData = strapiGetter.experience(data);
    return {
      title: _.get(pageData, "title"),
      slider: {
        isAuto: _.get(pageData, "slider.isAuto"),
        interval: _.get(pageData, "slider.interval"),
        slides: _.map(_.get(pageData, "slider.slides"), (item) => ({
          ..._.pick(item, ["title", "description"]),
          image: strapiGetter.image(_.get(item, "image")),
          button: strapiGetter.button(_.get(item, "readMoreButton")),
        })),
      },
    };
  },
};
const wellnessPage = {
  formatIntro: (data: any) => {
    const pageData = strapiGetter.intro(data);
    return {
      content: _.get(pageData, "content", ""),
      images: _.map(_.get(pageData, "listIcons"), (icon) => ({
        image: strapiGetter.image(_.get(icon, "image")),
        title: _.get(icon, "title"),
      })),
    };
  },
  formatAdditional: (data: any) => {
    const pageData = strapiGetter.additional(data);
    return {
      title: _.get(pageData, "title", ""),
      informations: _.map(_.get(pageData, "information"), (item) => ({
        title: _.get(item, "title", ""),
        content: _.get(item, "content", ""),
      })),
    };
  },
  formatGallery: (data: any) => {
    const pageData = strapiGetter.gallery(data);
    return {
      title: _.get(pageData, "title", ""),
      slider: {
        isAuto: _.get(pageData, "slider.isAuto"),
        interval: _.get(pageData, "slider.interval"),
        slides: _.map(
          _.get(pageData, "slider.slides.data", []),
          strapiGetter.image
        ),
      },
    };
  },
  formatWellbeingCentre: (data: any) => {
    const pageData = strapiGetter.wellbeingCentre(data);
    return {
      ..._.pick(pageData, [
        "title",
        "description",
        "openTime",
        "closeTime",
        "location",
        "phone",
      ]),
      image: strapiGetter.image(_.get(pageData, "image")),
    };
  },
  formatFacilities: (data: any) => {
    const pageData = strapiGetter.facilities(data);

    return _.map(pageData, (item) => ({
      ..._.pick(item, [
        "title",
        "description",
        "openTime",
        "closeTime",
        "location",
        "phone",
        "tabKey",
      ]),
      image: strapiGetter.image(_.get(item, "image")),
      button: strapiGetter.button(_.get(item, "actionButton")),
    }));
  },
  formatArticles: (data: any) => {
    const pageData = strapiGetter.articles(data);
    return _.map(pageData, (item) => ({
      ..._.pick(item, ["title", "subtitle", "description"]),
      image: strapiGetter.image(_.get(item, "image")),
      button: strapiGetter.button(_.get(item, "readMoreButton")),
    }));
  },
  formatStories: (data: any) => {
    const pageData = strapiGetter.stories(data);
    return {
      ..._.pick(pageData, ["title", "content"]),
    };
  },
  formatOffers: (data: any) => {
    const pageData = strapiGetter.offers(data);
    return { title: pageData };
  },
  formatWellnessOffers: (data: any) => {
    return _.map(data, helper.formatOfferItem);
  },
  formatWellnessStories: (data: any) => {
    // const pageData = strapiGetter.wellness(data);
    return _.map(data, (item) => ({
      title: articleGetter.title(item),
      content: articleGetter.description(item),
      slug: articleGetter.slug(item),
      image: articleGetter.image(item),
      // date: formatDateDisplay(articleGetter.publishedAt(data)),
      // tag: articleGetter.tag(item),
    }));
  },
};
const wellnessPage65 = {
  formatFacilitesAndServices: (data: any) => {
    const pageData = strapiGetter.facilitiesAndServices(data);
    return _.map(pageData, (item) => ({
      ..._.pick(item, [
        "title",
        "description",
        "openTime",
        "closeTime",
        "location",
        "phone",
        "tabKey",
      ]),
      image: strapiGetter.image(_.get(item, "image.data")),
      images: _.map(_.get(item, "images.data"), strapiGetter.image),
      button: strapiGetter.button(_.get(item, "actionButton")),
    }));
  },
};
const roomPage = {
  formatImages: (data: any) => {
    const pageData = strapiGetter.images(data);
    return _.map(pageData, strapiGetter.image);
  },
  formatRoomPage: (data: any) => {
    return {
      title: roomGetter.name(data),
      overview: {
        description: roomGetter.description(data),
        area: roomGetter.size(data),
        bed: roomGetter.bedInfo(data),
        capacity: roomGetter.capacity(data),
        view: roomGetter.location(data),
      },
      slug: "",
      image: strapiGetter.image(_.get(data, "attributes.thumbnail")),
      detail: {
        utilities: [],
        checkIn: "",
        checkOut: "",
      },
    };
  },
  formatFAQs: (data: any) => {
    const pageData = strapiGetter.FAQs(data);
    return {
      ..._.pick(pageData, ["title", "subtitle"]),
      data: _.map(_.get(pageData, "faqData", []), (item) =>
        _.pick(item, ["title", "content"])
      ),
    };
  },
};
const meetingPage = {
  formatIntro: (data: any) => {
    const pageData = strapiGetter.intro(data);
    return {
      ..._.pick(pageData, ["title", "phone", "email", "content"]),
      button: strapiGetter.button(_.get(pageData, "actionButton", {})),
    };
  },
  formatFeaturedImages: (data: any) => {
    const pageData = strapiGetter.featuredImages(data);
    return {
      ..._.pick(pageData, ["isAuto", "interval"]),
      slides: _.map(_.get(pageData, "slides.data", []), strapiGetter.image),
    };
  },
  formatPersonalizationAndDetails: (data: any) => {
    const pageData = strapiGetter.personalizationAndDetails(data);
    return {
      title: _.get(pageData, "title", ""),
      items: _.map(_.get(pageData, "items"), (item) => ({
        ..._.pick(item, ["title", "content", "imagePosition"]),
        image: strapiGetter.image(_.get(item, "image", {})),
        button: strapiGetter.button(_.get(item, "readMoreButton", {})),
      })),
    };
  },
  formatRequestForProposal: (data: any) => {
    const pageData = strapiGetter.requestForProposal(data);
    return _.pick(pageData, ["title", "content"]);
  },
  formatBannerFooter: (data: any) => {
    const pageData = strapiGetter.bannerFooter(data);
    return strapiGetter.image(pageData);
  },
};
const discoverPage = {
  formatIntroduce: (data: any) => {
    const pageData = strapiGetter.introduce(data);
    return {
      ..._.pick(pageData, ["title", "content"]),
      image: strapiGetter.image(_.get(pageData, "image")),
    };
  },

  formatDivider: (data: any) => {
    const pageData = strapiGetter.divider(data);
    return {
      ..._.pick(pageData, ["title", "content"]),
    };
  },
  formatRecommendation: (data: any) => {
    const pageData = strapiGetter.recommendation(data);
    return {
      ..._.pick(pageData, ["title", "content"]),
      image: strapiGetter.image(_.get(pageData, "image")),
    };
  },
  formatLocations: (data: any) => {
    return _.map(data, (item) => ({
      title: locationGetter.name(item),
      description: locationGetter.description(item),
      phone: locationGetter.phone(item),
      website: locationGetter.website(item),
      image: strapiGetter.image(_.get(item, "attributes.thumbnail")),
      direction: locationGetter.mapLink(item),
      distance: locationGetter.distance(item),
      button: "",
      footer: "",
    }));
  },
  formatTours: (data: any) => {
    return _.map(data, (item) => ({
      reminderText: tourGetter.reminderText(item),
      title: tourGetter.title(item),
      description: tourGetter.description(item),
      phone: tourGetter.phone(item),
      website: tourGetter.website(item),
      image: strapiGetter.image(_.get(item, "attributes.image")),
    }));
  },
  formatThroughTheLens: (data: any) => {
    const pageData = strapiGetter.throughTheLens(data);
    return {
      ..._.pick(pageData, ["title", "content"]),
      image: strapiGetter.image(_.get(pageData, "image")),
    };
  },
  formatArticles: (data: any) => {
    const pageData = strapiGetter.articles(data);
    return _.map(pageData, (item) =>
      helper.formatArticleItem(_.get(item, "article.data"))
    );
  },
  formatLearnDiscover: (data: any) => {
    const pageData = strapiGetter.learnDiscover(data);
    return {
      ..._.pick(pageData, ["title", "content"]),
      image: strapiGetter.image(_.get(pageData, "image")),
    };
  },
  formatOurLocation: (data: any) => {
    const pageData = strapiGetter.ourLocation(data);
    return {
      ..._.pick(pageData, ["title", "address", "phone"]),
      services: _.map(_.get(pageData, "shuttleServices", []), (item) => ({
        ..._.pick(item, ["name", "description", "mapLink"]),
        image: strapiGetter.image(_.get(item, "mapImage.data")),
      })),
    };
  },
};
const hotel65Page = {
  formatFeatured: (data: any) => {
    const pageData = strapiGetter.intro(data);
    return {
      ..._.pick(pageData, ["title", "subtitle", "content", "paragraph"]),
      image: strapiGetter.image(_.get(pageData, "image")),
      logo: strapiGetter.image(_.get(pageData, "logo")),
      button: strapiGetter.button(_.get(pageData, "actionButton")),
    };
  },
};
const homePage = {
  formatBanner: (data: any) => {
    const pageData = strapiGetter.bannerSlider(data);
    return {
      isAuto: _.get(pageData, "isAuto"),
      internal: _.get(pageData, "internal"),
      slides: _.map(_.get(pageData, "slides.data", []), strapiGetter.image),
      mobileSlides: _.map(
        _.get(pageData, "mobileSlides.data", []),
        strapiGetter.image
      ),
    };
  },

  formatAboutUs: (data: any) => {
    const pageData = strapiGetter.aboutUs(data);
    return {
      ..._.pick(pageData, ["title", "description", "content"]),
      image: strapiGetter.image(_.get(pageData, "image")),
      button: strapiGetter.button(_.get(pageData, "readMoreButton")),
    };
  },
  formatAmanakiLogo: (data: any) => {
    const pageData = strapiGetter.amanakiLogo(data);
    return strapiGetter.image(pageData);
  },
  formatMiddleBanner: (data: any) => {
    const pageData = strapiGetter.middleBanner(data);
    return {
      ..._.pick(pageData, ["displayType", "youtubeVideoEmbedLink"]),
      image: strapiGetter.image(_.get(pageData, "image")),
    };
  },
  formatOurJourney: (data: any) => {
    const pageData = strapiGetter.ourJourney(data);
    return {
      ..._.pick(pageData, ["title", "description"]),
      slider: {
        isAuto: _.get(pageData, "slider.isAuto"),
        interval: _.get(pageData, "slider.interval"),
        slides: _.map(_.get(pageData, "slider.slides", []), (item) => ({
          ..._.pick(item, ["title", "description"]),
          image: strapiGetter.image(_.get(item, "image")),
        })),
      },
    };
  },
  formatOurHotels: (data: any) => {
    const pageData = strapiGetter.ourHotel(data);
    return {
      title: _.get(pageData, "heading"),
      hotels: _.map(_.get(pageData, "listHotels", []), (item) => ({
        ..._.pick(item, ["title", "description"]),
        image: strapiGetter.image(_.get(item, "image")),
        button: strapiGetter.button(_.get(item, "readMoreButton")),
      })),
    };
  },
  formatOurStories: (data: any) => {
    const pageData = strapiGetter.ourStories(data);
    return {
      heading: {
        title: _.get(pageData, "heading.title", ""),
        button: strapiGetter.button(
          _.get(pageData, "heading.readMoreButton", {})
        ),
      },
      stories: _.map(_.get(pageData, "stories.data", []), (item) => {
        return {
          topic: articleGetter.category(item).name,
          image: articleGetter.image(item),
          date: formatDateDisplay(articleGetter.publishedAt(item)),
          title: articleGetter.title(item),
          description: articleGetter.description(item),
          slug: articleGetter.slug(item),
        };
      }),
    };
  },
};
const contactPage = {
  formatBanner: (data: any) => {
    const pageData = strapiGetter.banner(data);

    return {
      title: _.get(pageData, "title", ""),
      description: _.get(pageData, "content", ""),
      image: strapiGetter.image(_.get(pageData, "image", {})),
    };
  },
  formatGetInTouch: (data: any) => {
    const pageData = strapiGetter.getInTouch(data);
    return {
      title: _.get(pageData, "title", ""),
      description: _.get(pageData, "description", ""),
      typeOfEnquiry: _.get(pageData, "typeOfEnquiry", []).map((item: any) => {
        return {
          label: item.value,
          value: item.value,
        };
      }),
      hotels: _.get(pageData, "hotel", []).map((item: any) => {
        return {
          label: item.value,
          value: item.value,
        };
      }),
    };
  },
  formatHotel: (data: any) => {
    const pageData = strapiGetter.ourHotel(data);
    return {
      title: _.get(pageData, "title", ""),
      hotels: _.map(_.get(pageData, "hotels", []), (item) => ({
        ..._.pick(item, ["title", "address", "phone", "email"]),
        button: _.get(item, "actionButton", {}),
      })),
    };
  },
  formatBannerFooter: (data: any) => {
    const pageData = strapiGetter.bannerFooter(data);
    return strapiGetter.image(pageData);
  },
};
const sustainabilityPage = {
  formatSustainableSection: (data: any) => {
    const pageData = strapiGetter.sustainableSection(data);
    return {
      ..._.pick(pageData, ["title", "subtitle", "description"]),
      image: strapiGetter.image(_.get(pageData, "image")),
    };
  },
  formatPracticeSection: (data: any) => {
    const pageData = strapiGetter.practiceSection(data);
    return {
      ..._.pick(pageData, ["title", "subtitle", "description"]),
      image: strapiGetter.image(_.get(pageData, "image")),
      button: strapiGetter.button(_.get(pageData, "readMoreButton")),
    };
  },
  formatDataSection: (data: any) => {
    const pageData = strapiGetter.dataSection(data);
    return {
      ..._.pick(pageData, ["title"]),
      icons: _.map(_.get(pageData, "listIcons.data"), strapiGetter.image),
      infos: _.map(_.get(pageData, "dataInfo"), (item) => ({
        ..._.pick(item, ["data", "unit", "source"]),
      })),
    };
  },
  formatSustainableItems: (data: any) => {
    const pageData = strapiGetter.sustainableItems(data);
    return _.map(pageData, (item) => ({
      ..._.pick(item, ["title", "imagePosition", "content"]),
      image: strapiGetter.image(_.get(item, "image")),
    }));
  },
  formatPartnershipSection: (data: any) => {
    const pageData = strapiGetter.partnershipSection(data);
    return {
      ..._.pick(pageData, ["subtitle", "description"]),
      slider: {
        isAuto: _.get(pageData, "slider.isAuto"),
        interval: _.get(pageData, "slider.interval"),
        slides: _.map(
          _.get(pageData, "slider.slides.data", []),
          strapiGetter.image
        ),
      },
    };
  },
  formatImageSection: (data: any) => {
    const pageData = strapiGetter.imageSection(data);
    return strapiGetter.image(pageData);
  },
  formatArticles: (data: any) => {
    const pageData = strapiGetter.articles(data);
    return _.map(pageData, helper.formatArticleItem);
  },
};
const aboutUsPage = {
  formatFeaturedBanner: (data: any) => {
    const pageData = strapiGetter.heroSection(data);
    const aboveImages: { src: string; alt: string }[] = _.map(
      _.get(pageData, "aboveImage.data", []),
      (item) => {
        const image = strapiGetter.image(item);
        if (image && image.src) {
          return { src: image.src, alt: image.alt };
        }
        return { src: "", alt: "" };
      }
    );
    return {
      aboveImages: aboveImages,
      belowImage: strapiGetter.image(_.get(pageData, "belowImage")),
    };
  },
  formatFeaturedAbout: (data: any) => {
    const pageData = strapiGetter.featuredAbout(data);
    return _.map(pageData, (item: any) => ({
      ..._.pick(item, ["title"]),
      description: _.get(item, "content", ""),
      placement: item.imagePosition,
      image: strapiGetter.image(item.image),
    }));
  },
  formatCoreValues: (data: any) => {
    const pageData = strapiGetter.coreValues(data);
    return {
      title: _.get(pageData, "title", ""),
      coreValues: _.map(_.get(pageData, "coreValues", []), (item) => ({
        label: _.get(item, "title", ""),
        content: _.get(item, "description", ""),
        image: strapiGetter.image(_.get(item, "image", {})) ?? {
          src: "",
          alt: "",
        },
      })),
    };
  },
  formatBannerFooter: (data: any) => {
    const pageData = strapiGetter.bannerFooter(data);
    return strapiGetter.image(pageData);
  },
};
const careerPage = {
  formatPeopleAndCulture: (data: any) => {
    const pageData = strapiGetter.peopleAndCulture(data);
    return {
      ..._.pick(pageData, ["title", "description"]),
      images: _.map(_.get(pageData, "listImages.data", []), strapiGetter.image),
    };
  },
  formatHumans: (data: any) => {
    const pageData = strapiGetter.humans(data);
    return {
      ..._.pick(pageData, ["title", "description"]),
      articles: _.map(
        _.get(pageData, "articles.data", []),
        helper.formatArticleItem
      ),
    };
  },
  formatWorks: (data: any) => {
    const pageData = strapiGetter.works(data);
    return {
      ..._.pick(pageData, ["title", "description"]),
      careers: _.map(_.get(pageData, "careers", []), (item) => {
        return {
          workPlace: _.get(item, "career.data.attributes.workPlace"),
          jobTitle: _.get(item, "career.data.attributes.jobTitle"),
          description: _.get(item, "career.data.attributes.description"),
          closingDate: formatDateDisplay(
            _.get(item, "career.data.attributes.closingDate"),
            "YYYY-MM-DD"
          ),
          button: strapiGetter.button(_.get(item, "actionButton")),
        };
      }),
    };
  },
};
const privacyPage = {
  formatLegalNotice: (data: any) => {
    const pageData = strapiGetter.legalNotice(data);
    return _.map(pageData, (item) => ({
      ..._.pick(item, ["title", "content"]),
      label: _.get(item, "title"),
      value: _.get(item, "type") || _.get(item, "title"),
    }));
  },
};
export {
  offerPage,
  offerDetailPage,
  dinningPage,
  wellnessPage,
  wellnessPage65,
  artPage,
  brandStoryPage,
  roomPage,
  meetingPage,
  discoverPage,
  hotel65Page,
  homePage,
  contactPage,
  sustainabilityPage,
  careerPage,
  privacyPage,
  aboutUsPage,
};
export default helper;
