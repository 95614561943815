import React from "react";
import FormProvider from "@providers/FormProvider";
import { Form, useFormik } from "formik";
import helper from "@utils/helper";
import DatePicker from "@components/Formik/DatePicker";
import Select from "@components/Formik/Select";
import Button from "@components/Button";
import Link from "@components/Link";
import People from "@components/SearchControl/People";
import { formatPayload } from "@utils/dateFormatter";
import _ from "lodash";
import styles from "./BookingForm.module.scss";
import { useRouteContext } from "@providers/RouteProvider";
import { useTranslationContext } from "@providers/TranslationProvider";
import Close from "@components/Icons/Close";
import Yup from "@utils/yup";
import cx from "classnames";
import useDocumentClick from "@hooks/useDocumentClick";

const View = ({ onClose }: any) => {
  const { router } = useRouteContext();
  const { i18n } = useTranslationContext();
  const form: any = useFormik({
    initialValues: {
      hotel_code: "", // hotel 65: "70AS04",
      room_type: "",
      check_in: null,
      check_out: null,
      num_of_adults: 2,
      num_of_children: 0,
    },
    onSubmit: async (values) => {
      const payload = {
        ...values,
        check_in: formatPayload(_.get(values, "check_in") || ""),
        check_out: formatPayload(_.get(values, "check_out") || ""),
      };
      const url = helper.getBookingLink(payload);
      router.open(url);
    },
    validationSchema: Yup.object().shape({
      hotel_code: Yup.string().required(i18n.t("Form.required")),
    }),
  });
  _.assign(form, {
    canSubmit: !form.isSubmitting && form.isValid && form.dirty,
  });
  return (
    <FormProvider form={form}>
      <Form>
        <div className={styles.formBox}>
          <div className={styles.body}>
            <div className={styles.titleBox}>
              <div className={styles.title}>{i18n.t("Button.bookNow")}</div>
              <div className={cx("cursor-pointer")} onClick={onClose}>
                <Close size="32" />
              </div>
            </div>
            <Select
              className={styles.select}
              boxOptionProps={{ className: styles.boxOption }}
              optionProps={{ className: styles.option }}
              inputProps={{ className: styles.input }}
              // theme="dark"
              placeholder={i18n.t("Header.selectAHotel")}
              type="placeholderNoneAmin"
              name={"hotel_code"}
              options={[
                {
                  label: i18n.t("NAVS.amanakiThaoDien"),
                  key: "491275",
                  value: "491275",
                },
                {
                  label: i18n.t("NAVS.amanakiSaigonBoutique"),
                  key: "70AS04",
                  value: "70AS04",
                },
              ]}
            />
            <Select
              className={styles.select}
              boxOptionProps={{ className: styles.boxOption }}
              optionProps={{ className: styles.option }}
              inputProps={{ className: styles.input }}
              // theme="dark"
              placeholder={i18n.t("Form.placeholderRoomType")}
              type="placeholderNoneAmin"
              name={"room_type"}
              options={[
                {
                  label: i18n.t("Rooms.Signature"),
                  key: "signature",
                  value: "signature",
                },
                {
                  label: i18n.t("Rooms.Executive"),
                  key: "executive",
                  value: "executive",
                },
                {
                  label: i18n.t("Rooms.Premium"),
                  key: "Premium",
                  value: "Premium",
                },
                {
                  label: i18n.t("Rooms.FamilySuite"),
                  key: "family_suite",
                  value: "family_suite",
                },
              ]}
            />
            <div className={cx("flex gap-6", styles.item)}>
              <div className="flex-1">
                <DatePicker
                  border
                  customInputProps={{ className: styles.inputStyle }}
                  name={"check_in"}
                  placeholder={i18n.t("Form.placeholderArrivalDate")}
                />
              </div>
              <div className="flex-1">
                <DatePicker
                  border
                  customInputProps={{ className: styles.inputStyle }}
                  name={"check_out"}
                  placeholder={i18n.t("Form.placeholderDepartureDate")}
                />
              </div>
            </div>
            <div className={styles.item}>
              <People className={styles.people} border />
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.submit}>
              <Button
                type="primary"
                disabled={!form.canSubmit}
                onClick={form.handleSubmit}
                className={cx(styles.button, "uppercase")}
                label={i18n.t("SearchControl.checkAvailability")}
              />
            </div>

            <Link
              className={styles.link}
              href={router.toUrl("privacy", {
                legalNoticeType: "BestRateGuaranteed",
              })}
              label={i18n.t("SearchControl.bestPrice")}
            />
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};
export const Dropdown = ({ className, itemProps = {}, label }: any) => {
  const currentRef: any = React.useRef({});
  const dropdownArea: any = React.useRef({});
  const handler = {
    show: () => {
      if (currentRef && currentRef.current) {
        currentRef.current.classList.add(styles.active);
        _.debounce(() => {
          currentRef.current.classList.add(styles.overflowInitial);
        }, 500)();
      }
    },
    hide: () => {
      if (currentRef && currentRef.current) {
        currentRef.current.classList.remove(styles.overflowInitial);
        currentRef.current.classList.remove(styles.active);
      }
    },
    toggle: (event: any) => {
      if (currentRef && currentRef.current) {
        const isArea =
          dropdownArea &&
          !_.isEmpty(dropdownArea.current) &&
          dropdownArea.current.contains(event.target);
        if (currentRef.current.classList.contains(styles.active)) {
          if (!isArea) {
            //   currentRef.current.classList.remove(styles.active);
            handler.hide();
          }
        } else {
          handler.show();
          //   currentRef.current.classList.add(styles.active);
        }
      }
    },

    handleClickOutside: (event: any) => {
      const isArea =
        dropdownArea &&
        !_.isEmpty(dropdownArea.current) &&
        dropdownArea.current.contains(event.target);
      if (
        currentRef &&
        currentRef.current &&
        !currentRef.current.contains(event.target)
      ) {
        if (!isArea) {
          if (currentRef.current.classList.contains(styles.active))
            handler.hide();
        }
      }
    },

    onBlur: () => {
      if (currentRef && currentRef.current.classList.contains(styles.active)) {
        currentRef.current.classList.remove(styles.active);
      }
    },
  };
  useDocumentClick(handler.handleClickOutside);

  return (
    <div ref={currentRef} className={cx(styles.dropdown, className)}>
      <div {...itemProps} onClick={handler.toggle}>
        {!!label && label}
      </div>
      {
        <div className={styles.area} ref={dropdownArea}>
          <View onClose={handler.hide} />
        </div>
      }
    </div>
  );
};
export default View;
