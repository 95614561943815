import _ from "lodash";
import Button from "@components/Button";
import styles from "./MenuModal.module.scss";
import cx from "classnames";

const View = ({ data, onChange, subLabel }: any) => {
  return (
    <div className={cx("inner-2", styles.subNavs)}>
      {!!subLabel && (
        <div className={cx("ama-section-title", styles.subLabel)}>
          {subLabel}
        </div>
      )}
      {_.map(data, (item: any, index: number) => {
        return (
          <Button
            onClick={() => onChange(item)}
            className={styles.nav}
            key={`nav_${index}`}
            label={item.label}
            type="default"
          />
        );
      })}
    </div>
  );
};
export default View;
