import React from "react";
import styles from "./Expand.module.scss";
import cx from "classnames";

const View = ({ label, content, className, showDesktop = false }: any) => {
  const [show, $show] = React.useState(false);
  return (
    <section
      className={cx(styles.section, className, {
        [styles.expanded]: show,
        [styles.showDesktop]: showDesktop,
      })}
    >
      <div className={styles.label} onClick={() => $show(!show)}>
        {!!label && label}
      </div>
      <div className={styles.content}>{!!content && content}</div>
    </section>
  );
};
export default View;
