import ButtonWithContent from "@components/Common/ButtonWithContent";
import getter from "@strapiCMS/getter";
import offerGetter from "@services/OfferService/getter";
import _ from "lodash";
import { usePageContext } from "@providers/PageProvider";
const View = (props: any) => {
  const { offer } = usePageContext();
  const item = {
    title: _.get(props, "title"),
    description: _.get(props, "description"),
    button: getter.button(_.get(props, "actionButton")),
    valid: offerGetter.expiryDate(offer),
  };
  return <ButtonWithContent {...item} />;
};

View.strapiName = "common.button-with-content";

export default View;
