import Swiper from "@components/Swiper";
import Item from "./Item";
import styles from "./Swiper.module.scss";
import cx from "classnames";
import useSwiper from "@hooks/useSwiper";
import _ from "lodash";

const View = ({ images }: any) => {
  // if (_.isEmpty(images)) return null;

  useSwiper(".swiper-article", {
    // rewind: true,
    spaceBetween: 32,
    speed: 1000,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      1280: {
        slidesPerView: "auto",
      },
    },
    // navigation: {
    //   nextEl: ".swiper-arrow-next-dinning-experiencing",
    //   prevEl: ".swiper-arrow-prev-dinning-experiencing",
    // },
  });
  return (
    <Swiper className={cx("swiper-article", styles.swiper)}>
      {_.map(images, (img) => {
        return <Item className="swiper-slide" image={img} />;
      })}
    </Swiper>
  );
};
export default View;
