import React from "react";
// import Image from "@components/Image";
import styled from "styled-components";
import _ from "lodash";

const Paragraph = styled.p`
  margin-bottom: 0.5rem;
`;

const View = (props: any) => {
  return <Paragraph {...props} />;
};

export default View;
