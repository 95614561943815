import Images from "@components/Article/Gallery/Images";
import getter from "@strapiCMS/getter";
import _ from "lodash";
const View = (props: any) => {
  const images = _.map(_.get(props, "images.data", []), (item) => ({
    src: getter.url(item),
    alt: getter.alt(item),
  }));
  return <Images images={images} />;
};

View.strapiName = "story.images";

export default View;
