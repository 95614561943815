import _ from "lodash";
import service from "@utils/apiService";
const formatter = {
  id: (item: any) => {
    return _.get(item, "id") || "";
  },
  title: (item: any) => {
    return _.get(item, "attributes.title") || "";
  },
  description: (item: any) => {
    return _.get(item, "attributes.description") || "";
  },
  readingTime: (item: any) => {
    return _.get(item, "attributes.readingTime") || "";
  },
  startDate: (item: any) => {
    return _.get(item, "attributes.startDate") || "";
  },
  endDate: (item: any) => {
    return _.get(item, "attributes.endDate") || "";
  },
  publishedAt: (item: any) => {
    return _.get(item, "attributes.publishedAt") || "";
  },
  slug: (item: any) => {
    return _.get(item, "attributes.slug") || "";
  },
  category: (item: any) => {
    const data =
      _.get(item, "attributes.category.data") ||
      _.get(item, "data.attributes") ||
      {};
    return {
      name: _.get(data, "attributes.name"),
      slug: _.get(data, "attributes.slug"),
    };
  },
  details: (item: any) => {
    return _.get(item, "attributes.details") || [];
  },
  tags: (item: any) => {
    return _.get(item, "attributes.tags") || [];
  },
  thumbnail: (item: any) => {
    return (
      service.strapiUrl(
        _.get(item, "attributes.thumbnail.data.attributes.url")
      ) || ""
    );
  },
  image: (item: any) => {
    return {
      src:
        service.strapiUrl(
          _.get(item, "attributes.thumbnail.data.attributes.url")
        ) || "",
      alt:
        _.get(item, "attributes.thumbnail.data.attributes.alternativeText") ||
        "",
    };
  },
};
export default formatter;
