import TextImage from "@components/Article/TextImage";
import getter from "@strapiCMS/getter";
import _ from "lodash";
const View = (props: any) => {
  const item = _.get(props, "image.data");
  const image = {
    src: getter.url(item),
    alt: getter.alt(item),
  };
  return (
    <TextImage
      title={_.get(props, "title")}
      description={_.get(props, "text")}
      imagePosition={_.get(props, "imagePosition")}
      image={image}
    />
  );
};

View.strapiName = "story.text-image-layout";

export default View;
