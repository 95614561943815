import cx from "classnames";

interface Props {
  className?: string | { [key: string]: string };
  children?: any;
  scrollbar?: any;
  swiperInner?: any;
}

const View = ({
  className,
  children,
  scrollbar = null,
  swiperInner = null,
}: Props) => {
  return (
    <div className={cx("swiper", className)}>
      <div className="swiper-wrapper">{children}</div>
      {scrollbar}
      {swiperInner}
    </div>
  );
};
export default View;
