import * as Yup from "yup";
import { formatComas } from "@utils/currencyFormatter";
import _ from "lodash";

import { StringSchema, NumberSchema } from "yup";

declare module "yup" {
  interface StringSchema {
    price(): StringSchema;
    priceNotZero(errorMessage: string): StringSchema;
  }
  interface NumberSchema {
    numberZero(): NumberSchema;
  }
}
Yup.addMethod<NumberSchema>(Yup.number, "numberZero", function () {
  return this.transform((value) => {
    console.log("numberZero", { value });
    return _.isEmpty(value) ? 0 : value;
  });
});
Yup.addMethod<StringSchema>(Yup.string, "price", function () {
  return this.transform((value) => {
    return _.isString(value) ? "0" : formatComas(value);
  });
});
Yup.addMethod<StringSchema>(
  Yup.string,
  "priceNotZero",
  function (errorMessage) {
    return this.transform((value) => {
      return _.isEmpty(value) ? "0" : formatComas(value);
    }).test("test-price-not-zero", errorMessage, function (value) {
      const { path, createError } = this;
      return (
        !_.isEqual(value, "0") || createError({ path, message: errorMessage })
      );
    });
  }
);
export default Yup;
