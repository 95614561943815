import React from "react";
import Link from "next/link";
import cx from "classnames";
interface LinkProps {
  href?: string;
  label?: string;
  className?: string;
  color?: string;
  target?: string;
  [key: string]: any;
}
const View = ({
  label,
  children,
  style,
  className,
  target,
  color = "",
  href = "#",
  ariaLabel = "amanaki link",
  ...props
}: LinkProps) => {
  return (
    <Link
      target={target}
      style={style}
      aria-label={ariaLabel}
      className={cx(`text-${color}`, {
        [`${className}`]: !!className,
      })}
      href={href}
      {...props}
    >
      {!!children ? children : label}
    </Link>
  );
};
export default View;
