import _ from 'lodash';
import i18n from '@translations/i18n';

export const normalizeNumber = (num: any) => {
  if (!num) return 0;
  let currNum = 0;
  try {
    currNum = _.toNumber(num.toString().replace(/,/g, ''));
  } catch (error) {}

  if (!currNum) return 0;

  return _.round(currNum, 1);
};
const normalizeParams = (currency: string) => {
  if (!currency) currency = 'vnd';
  if (currency === 'đ') currency = 'vnd';
  return i18n.t(`CurrencyFormat.${currency}`);
};
export const formatComas = (num: any) => {
  return `${normalizeNumber(num)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};
export const formatPrice = (num: any, currency = 'vnd') => {
  // Chổ này còn cần làm thêm nếu trường hợp muốn hiển thị dollar thì currency phải nằm phía trước. Có thể dùng i18n.t để làm việc này.
  return `${normalizeNumber(num)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} ${normalizeParams(currency)}`;
};
