import _ from "lodash";
const normalizeArgs = (fn: any) => {
  if (fn && fn.matcher) return fn;

  return {
    matcher: () => true,
    render: fn,
  };
};

const renderPropsComposer = (...fns: any) => {
  const ListFns = _.map(fns, normalizeArgs);
  return (props: any) => {
    const renderPropsObj = _.find(ListFns, (fn) => {
      if (_.isFunction(fn.matcher)) {
        return fn.matcher(props);
      }
      return false;
    });
    if (renderPropsObj) {
      return renderPropsObj.render(props);
    }
    const renderPropsDefault = _.find(ListFns, (fn) =>
      _.isEqual(fn.matcher, "default")
    );
    if (renderPropsDefault) {
      return renderPropsDefault.render(props);
    }
    // default
    return null;
  };
};

export default renderPropsComposer;
