import styles from "./Swiper.module.scss";
import cx from "classnames";
import Image from "@components/Image";
import _ from "lodash";
const View = ({ image, className }: any) => {
  return (
    <div className={cx("slide-item animated", styles.item, className)}>
      <Image
        wrapperProps={{ className: cx(styles.image) }}
        src={_.get(image, "src")}
        alt={_.get(image, "alt")}
        layout="fill"
        objectFit="cover"
      />
    </div>
  );
};
export default View;
