import React from "react";
import styles from "./MenuMobileModal.module.scss";
import Dropdown from "@components/Dropdown";
import Button from "@components/Button";
// import Divider from "@components/Divider";
import Arrow from "@components/Icons/Arrow";
import Close from "@components/Icons/Close";
import Image from "@components/Image";
import Icons from "@gstyles/styleguide/icons";
import gstyles from "@gstyles/index";
import { useTranslationContext } from "@providers/TranslationProvider";
import { getCurrentLocaleConfig } from "@translations/util";

import _ from "lodash";
import cx from "classnames";
import { useRouteContext } from "@providers/RouteProvider";
import { useModalContext } from "@providers/ModalProvider";
import { useBookingContext } from "@providers/BookingProvider";
import { Dropdown as BFDropdown } from "@components/BookingForm";
import { usePageContext } from "@providers/PageProvider";
import helper from "@utils/helper";
const MenuInner = ({ data, onClickNav, showTitle = true }: any) => {
  return (
    <div className={cx("menu-inner", styles.navs)}>
      {!!showTitle && (
        <div className={cx("ama-section-title", styles.title)}>
          {_.get(data, "title")}
        </div>
      )}
      {_.map(_.get(data, "data", []), (nav: any, index: number) => {
        return (
          <div
            onClick={() => {
              !!onClickNav && onClickNav(nav);
            }}
            className={cx("ama-content flex items-center", styles.nav, {
              // [styles.active]: currentNav === nav.label,
            })}
            key={`nav_${index}`}
          >
            <div className="flex items-center amin-underline">
              {nav.label}
              {!_.isEmpty(nav.child) && <Arrow className={styles.arrow} />}
            </div>
          </div>
        );
      })}
    </div>
  );
};
const View = ({ NAVS, MENU_GROUP }: any) => {
  const { i18n } = useTranslationContext();
  const { router } = useRouteContext();
  const { modalService } = useModalContext();
  const { hotel } = usePageContext();
  const [currentNav, $currentNav] = React.useState("");
  const [showSubs, $showSubs] = React.useState(false);
  const { booking } = useBookingContext();
  const handler = {
    onClickNav: (nav: any) => {
      const link = _.get(nav, "href", "");
      if (helper.isExternal(link)) {
        return router.open(link);
      }
      if (link) {
        return router.push(link);
      }
      if (nav && nav.label) {
        if (nav.label !== currentNav) $currentNav(nav.label);
        _.debounce(() => {
          handler.showSub();
        }, 50)();
      }
    },
    reset: () => {
      $currentNav("");
      //   $isSwitch(false);
    },
    showSub: () => {
      $showSubs(true);
    },
    hideSub: () => {
      $showSubs(false);
    },
    onClose: () => modalService.removeDOM(400, handler.reset),
    book: () => {
      booking.open({ hotel });
    },
  };
  const ALL_NAVS_DATA = [
    ..._.get(NAVS, "data", []),
    ..._.get(MENU_GROUP, "data", []),
  ];
  const isGroup = !hotel || hotel === "group";
  const current = _.find(ALL_NAVS_DATA, (nav) => nav.label === currentNav);
  const data = _.get(current, "child", []);
  const image = _.get(current, "src", "");
  return (
    <>
      <div
        className={cx(
          "flex flex-col modal-menu-mobile fixed w-full",
          styles.box
        )}
      >
        <div>
          <div
            className={cx(
              "menu-inner flex justify-between items-center",
              styles.language
            )}
          >
            <Dropdown
              className={styles.dropdown}
              optionProps={{ className: styles.option }}
              itemProps={{ className: styles.currentItem }}
              value={i18n.language}
              options={[
                {
                  label: <Icons name="flag-en" size={20} />,
                  value: "en",
                },
                { label: <Icons name="flag-vn" size={20} />, value: "en" },
              ]}
              onChange={(opt: any) => {
                const localeInstance = getCurrentLocaleConfig();
                localeInstance.setLanguage(opt.value);
                const baseUrl = localeInstance.withLangPrefix(
                  `${_.get(router, "location.pathname")}${_.get(
                    router,
                    "location.search"
                  )}`
                );
                router.languageTo(baseUrl);
              }}
            />
          </div>
          {/* {hotel !== "group" && (
            <div className={cx("menu-inner", styles.navs)}></div>
          )} */}
          {!isGroup && (
            <MenuInner data={MENU_GROUP} onClickNav={handler.onClickNav} />
          )}
          <MenuInner
            data={NAVS}
            onClickNav={handler.onClickNav}
            showTitle={!isGroup}
          />
          {/* <div className={cx("menu-inner", styles.navs)}>
            {_.get(NAVS, "title")}
            {_.map(_.get(NAVS, "data", []), (nav: any, index: number) => {
              return (
                <div
                  onClick={() => {
                    handler.onClickNav(nav);
                  }}
                  className={cx("ama-content flex items-center", styles.nav, {
                    // [styles.active]: currentNav === nav.label,
                  })}
                  key={`nav_${index}`}
                >
                  {nav.label}
                  {!_.isEmpty(nav.child) && <Arrow className={styles.arrow} />}
                </div>
              );
            })}
          </div> */}
        </div>
        <div>
          <div className={cx("menu-inner", styles.booking)}>
            <BFDropdown
              className={styles.dropdown}
              itemProps={{ className: styles.label }}
              optionsProps={{ className: styles.options }}
              optionProps={{ className: styles.option }}
              label={
                <Button
                  // onClick={handler.book}
                  label={i18n.t("Button.bookNow")}
                  color="yellow"
                  className={styles.button}
                />
              }
            />
          </div>
          <div className={cx("menu-inner flex flex-col gap-5", styles.hotel)}>
            <Button
              onClick={() => {
                // router.open("https://amanakihotels.com/");
                router.push("amanaki-boutique-saigon");
              }}
              label={i18n.t("Footer.boutiqueSaigon")}
              className={styles.button}
            />

            <Button
              onClick={() => {
                router.push("amanaki-thao-dien");
              }}
              label={i18n.t("Footer.thaoDien")}
              className={styles.button}
            />
          </div>
          <div className={cx("menu-inner", styles.info)}>
            <Button
              onClick={() => {
                router.push("privacy");
              }}
              label={i18n.t("Footer.privacyPolicy")}
              type="text"
            />
          </div>
        </div>
      </div>
      <div
        className={cx("z-10 level-2", styles.level2, {
          [styles.showSub]: showSubs,
        })}
      >
        <div className={cx("menu-inner", styles.control)}>
          <div
            className="flex items-center uppercase cursor-pointer"
            onClick={handler.hideSub}
          >
            {gstyles.icons({
              name: "menu-arrow-left",
              size: 32,
              className: "mr-4",
            })}{" "}
            {i18n.t("Button.back")}
          </div>
          <Close color="#f6eedf" onClick={handler.onClose} />
        </div>
        <div className={styles.title}>{currentNav}</div>
        <div className={cx("menu-inner", styles.subnavs)}>
          <div className={cx("menu-inner", styles.boxNav)}>
            {_.map(data, (item: any, index: number) => {
              return (
                <Button
                  onClick={() => handler.onClickNav(item)}
                  className={cx(styles.nav)}
                  key={`nav_${index}`}
                  label={item.label}
                  type="default"
                />
              );
            })}
          </div>
          <div className={styles.image}>
            <Image
              wrapperProps={{ className: styles.image }}
              src={image}
              layout="fill"
              objectFit="cover"
            />
          </div>
          <div className="absolute inset-0 z-10 w-full h-full bg-black/70"></div>
        </div>
      </div>
    </>
  );
};
export default View;
