import React, { createContext, useContext, ReactNode } from "react";
import {
  AppRouteContext,
  AppRouteProps,
  TypeRoutesContext,
} from "@type/provider/Route";
import useRoute from "@hooks/useNextRoute";
import routeStore, { helper } from "@utils/routeStore";
import i18n from "@translations/i18n";
import _ from "lodash";
import cx from "classnames";
export const RouteContext = createContext<AppRouteContext>({
  router: {
    push: () => {},
  },
});

interface TypeUseRouteContext {
  (): AppRouteContext;
}
// create rule for every routing;
routeStore.addRule("home", {
  url: () => {
    return helper.url("/");
  },
});
routeStore.addRule("contact", {
  url: () => {
    return helper.url("contact");
  },
});
routeStore.addRule("privacy", {
  url: (params?: object) => {
    return helper.url("privacy-policy", params);
  },
  breadcrumbs: (params: object) => {
    return _.get(params, "breadcrumbs", [
      {
        name: i18n.t("LegalNotice.banner.title"),
      },
    ]);
  },
});
routeStore.addRule("about-us", {
  url: () => {
    return helper.url("about-us");
  },
  breadcrumbs: (params: object) => {
    return _.get(params, "breadcrumbs", [
      {
        name: i18n.t("About.aboutAmanaki"),
        type: "link",
        to: () => useRoute().toUrl("home"),
      },
      {
        name: i18n.t("About.aboutUs"),
      },
    ]);
  },
});
routeStore.addRule("about-sustainability", {
  url: () => {
    return helper.url("about-sustainability");
  },
  breadcrumbs: (params: object) => {
    return _.get(params, "breadcrumbs", [
      {
        name: i18n.t("About.aboutAmanaki"),
        type: "link",
        to: () => useRoute().toUrl("home"),
      },
      {
        name: i18n.t("About.sustainableDevelopment"),
      },
    ]);
  },
});
routeStore.addRule("about-careers", {
  url: () => {
    return helper.url("about-careers");
  },
  breadcrumbs: (params: object) => {
    return _.get(params, "breadcrumbs", [
      {
        name: i18n.t("About.aboutAmanaki"),
        type: "link",
        to: () => useRoute().toUrl("home"),
      },
      {
        name: i18n.t("About.careers"),
      },
    ]);
  },
});
routeStore.addRule("origin-stories", {
  url: (params?: object) => {
    return helper.url("stories", params);
  },
});
routeStore.addRule("stories", {
  url: (params?: object) => {
    return helper.urlHotel("stories", params);
  },
});
routeStore.addRule("storiesCategory", {
  url: (params?: object) => {
    return helper.urlHotel("stories/category", params);
  },
});
routeStore.addRule("storiesArticle", {
  url: (params?: object) => {
    return helper.urlHotel("stories/article", params);
  },
  breadcrumbs: (params: object) => {
    const hotel: string = _.get(params, "hotel") || "amanaki-thao-dien";
    return _.get(params, "breadcrumbs", [
      {
        name: cx(i18n.t(`Breadcrumb.${hotel}`)),
        type: "link",
        to: () => useRoute().toUrl(hotel),
      },
      {
        name: i18n.t("Breadcrumb.stories"),
        type: "link",
        to: () => useRoute().toUrl("stories"),
      },
      { name: _.get(params, "name") || _.get(params, "title") },
    ]);
  },
});
routeStore.addRule("amanaki-thao-dien", {
  url: () => {
    return helper.url("amanaki-thao-dien");
  },
});

routeStore.addRule("room-detail", {
  url: (params?: object) => {
    return helper.urlHotel("rooms", params);
  },
  breadcrumbs: (params: object) => {
    const hotel: string = _.get(params, "hotel") || "amanaki-thao-dien";
    return _.get(params, "breadcrumbs", [
      {
        name: cx(i18n.t(`Breadcrumb.${hotel}`)),
        type: "link",
        to: () => useRoute().toUrl(hotel),
      },
      {
        name: "ROOMS",
        type: "link",
        to: () => useRoute().toUrl("rooms"),
      },
      { name: _.get(params, "name") || _.get(params, "title") },
    ]);
  },
});
routeStore.addRule("wellness", {
  url: (params?: object) => {
    return helper.urlHotel("wellness", params);
  },
  breadcrumbs: (params: object) => {
    const hotel: string = _.get(params, "hotel") || "amanaki-thao-dien";
    return _.get(params, "breadcrumbs", [
      {
        name: cx(i18n.t(`Breadcrumb.${hotel}`)),
        type: "link",
        to: () => useRoute().toUrl(hotel),
      },
      {
        name: i18n.t("Wellness.wellness"),
      },
    ]);
  },
});
routeStore.addRule("facilities-service", {
  url: (params?: object) => {
    return helper.urlHotel("facilities-service", params);
  },
  breadcrumbs: (params: object) => {
    const hotel: string = _.get(params, "hotel") || "amanaki-thao-dien";
    return _.get(params, "breadcrumbs", [
      {
        name: cx(i18n.t(`Breadcrumb.${hotel}`)),
        type: "link",
        to: () => useRoute().toUrl(hotel),
      },
      {
        name: i18n.t("Wellness.facilitiesService"),
      },
    ]);
  },
});
routeStore.addRule("offers", {
  url: () => {
    return helper.urlHotel("offers");
  },
  breadcrumbs: (params: object) => {
    const hotel: string = _.get(params, "hotel") || "amanaki-thao-dien";
    return _.get(params, "breadcrumbs", [
      {
        name: cx(i18n.t(`Breadcrumb.${hotel}`)),
        type: "link",
        to: () => useRoute().toUrl(hotel),
      },
      {
        name: i18n.t("Offers.offer"),
      },
    ]);
  },
});

routeStore.addRule("offer-detail", {
  url: (params?: object) => {
    return helper.urlHotel("offers", params);
  },
  breadcrumbs: (params: object) => {
    const hotel: string = _.get(params, "hotel") || "amanaki-thao-dien";
    return _.get(params, "breadcrumbs", [
      {
        name: cx(i18n.t(`Breadcrumb.${hotel}`)),
        type: "link",
        to: () => useRoute().toUrl(hotel),
      },
      {
        name: i18n.t("Offers.offer"),
        type: "link",
        to: () => useRoute().toUrl("offers"),
      },
      {
        name: _.get(params, "title", "OFFERS"),
      },
    ]);
  },
});

routeStore.addRule("dinning", {
  url: (params?: object) => {
    return helper.urlHotel("dinning", params);
  },
  breadcrumbs: (params: object) => {
    const hotel: string = _.get(params, "hotel") || "amanaki-thao-dien";
    return _.get(params, "breadcrumbs", [
      {
        name: cx(i18n.t(`Breadcrumb.${hotel}`)),
        type: "link",
        to: () => useRoute().toUrl(hotel),
      },
      {
        name: i18n.t("Dinning.dinning"),
      },
    ]);
  },
});
routeStore.addRule("meeting-and-events", {
  url: (params?: object) => {
    return helper.urlHotel("meeting-and-events", params);
  },
  breadcrumbs: (params: object) => {
    const hotel: string = _.get(params, "hotel") || "amanaki-thao-dien";
    return _.get(params, "breadcrumbs", [
      {
        name: cx(i18n.t(`Breadcrumb.${hotel}`)),
        type: "link",
        to: () => useRoute().toUrl(hotel),
      },
      {
        name: i18n.t("MeetingAndEvents.meetingAndCelebrations"),
      },
    ]);
  },
});
routeStore.addRule("rooms", {
  url: () => {
    return helper.urlHotel("rooms");
  },
});

routeStore.addRule("brand-story", {
  url: (params?: object) => {
    return helper.urlHotel("brand-story", params);
  },
  breadcrumbs: (params: object) => {
    const hotel: string = _.get(params, "hotel") || "amanaki-thao-dien";
    return _.get(params, "breadcrumbs", [
      {
        name: cx(i18n.t(`Breadcrumb.${hotel}`)),
        type: "link",
        to: () => useRoute().toUrl(hotel),
      },
      {
        name: i18n.t("Breadcrumb.theHotel"),
        type: "link",
        to: () => useRoute().toUrl("brand-story"),
      },
      {
        name: i18n.t("BrandStory.banner.title"),
      },
    ]);
  },
});
routeStore.addRule("arts", {
  url: (params?: object) => {
    return helper.urlHotel("arts", params);
  },
  breadcrumbs: (params: object) => {
    const hotel: string = _.get(params, "hotel") || "amanaki-thao-dien";
    return _.get(params, "breadcrumbs", [
      {
        name: cx(i18n.t(`Breadcrumb.${hotel}`)),
        type: "link",
        to: () => useRoute().toUrl(hotel),
      },
      {
        name: i18n.t("Breadcrumb.theHotel"),
        type: "link",
        to: () => useRoute().toUrl("brand-story"),
      },
      {
        name: i18n.t("Arts.banner.title"),
      },
    ]);
  },
});

routeStore.addRule("discover", {
  url: () => {
    return helper.urlHotel("discover");
  },
  breadcrumbs: (params: object) => {
    const hotel: string = _.get(params, "hotel") || "amanaki-thao-dien";
    return _.get(params, "breadcrumbs", [
      {
        name: cx(i18n.t(`Breadcrumb.${hotel}`)),
        type: "link",
        to: () => useRoute().toUrl(hotel),
      },
      {
        name: i18n.t("Discover.title"),
      },
    ]);
  },
});

// rule follow hotel data
routeStore.addRule("amanaki-boutique-saigon", {
  url: () => {
    return helper.url("amanaki-boutique-saigon");
  },
});

routeStore.addRule("theHotel", {
  url: () => {
    return helper.urlHotel("the-hotel");
  },
  breadcrumbs: (params: object) => {
    const hotel: string = _.get(params, "hotel") || "amanaki-thao-dien";
    return _.get(params, "breadcrumbs", [
      {
        name: cx(i18n.t(`Breadcrumb.${hotel}`)),
        type: "link",
        to: () => useRoute().toUrl(hotel),
      },
      {
        name: i18n.t("About.aboutUs"),
      },
    ]);
  },
});

const RouteProvider = ({ children }: AppRouteProps): ReactNode => {
  // define all route's method to use;
  const nextRouter = useRoute();

  const router: TypeRoutesContext = {
    ...nextRouter,
    push: (path: string, params?: object) => {
      path = _.first(path) === "/" ? `${path}`.slice(1) : `${path}`;
      const baseUrl = nextRouter.toUrl(path, params);
      if (!!baseUrl) {
        nextRouter.push(baseUrl, null, { shallow: false });
      }
    },
    languageTo: (baseUrl: string) => {
      router.location.href = baseUrl;
      // nextRouter.push(baseUrl, null, { shallow: true });
    },
  };
  return (
    <RouteContext.Provider value={{ router }}>{children}</RouteContext.Provider>
  );
};
export const useRouteContext: TypeUseRouteContext = () => {
  return useContext(RouteContext);
};
export default RouteProvider;
