import React from "react";
import { useTranslationContext } from "@providers/TranslationProvider";
import Button from "@components/Button";
import Link from "@components/Link";
import styles from "./ButtonWithContent.module.scss";
import cx from "classnames";
import _ from "lodash";
import { formatDateDisplay } from "@utils/dateFormatter";

const View = ({ title, description, valid, button }: any) => {
  const { i18n } = useTranslationContext();
  const handler = {
    book: () => {
      if (!button || _.get(button, "href") === "#") {
        // booking.open({});
      }
    },
  };
  return (
    <section className={cx("inner-large", styles.section)}>
      <div className={styles.box}>
        <div
          className={cx(
            "text-center ama-section-title text-black-1",
            styles.title
          )}
        >
          {!!title && title}
        </div>
        <div
          className={cx(
            "text-center ama-content text-black-1",
            styles.description
          )}
        >
          {!!description && description}
        </div>
        <div className={cx(styles.button)}>
          <Link href={_.get(button, "href", "")}>
            <Button
              onClick={handler.book}
              label={_.get(button, "label")}
              type="primary"
              color="black"
            />
          </Link>
        </div>

        <div className={cx("ama-content text-yellow-1", styles.valid)}>
          {!!valid &&
            i18n.t("Offer.valid", {
              date: formatDateDisplay(valid, "YYYY-MM-DD"),
            })}
        </div>
      </div>
    </section>
  );
};
export default View;
